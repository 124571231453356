import request from '@/tools/request'

export const state = () => ({
  crewArr: [],
  crewConfig: {},
  events: [],
})

export const mutations = {
  addCrews(state, crewArr) {
    state.crewArr = crewArr
  },
  addCrewConfig(state, crewConfig) {
    const crewid = crewConfig.id
    state.crewConfig = { ...state.crewConfig, [crewid]: { ...crewConfig } }
  },
  addCrewEvents(state, crewEvent) {
    if (crewEvent.crewInfo.id !== undefined) {
      const crewid = crewEvent.crewInfo.id
      state.events = { ...state.events, [crewid]: { ...crewEvent } }
      console.log('addEvents', state.events)
    }
  },
}

export const getters = {
  crewColour: (state) => (crewid) => {
    const findCrew = state.crewArr.find((x) => x.crewid === crewid)
    return findCrew ? findCrew.colour : '000'
  },
  crewDetails: (state) => (crewid) => {
    const findCrew = state.crewArr.find(
      (x) => String(x.crewid) === String(crewid)
    )
    return findCrew || {}
  },
  crewConfig: (state) => (crewid) => {
    let findCrewConfig = {}
    if (Object.keys(state.crewConfig).includes(crewid)) {
      findCrewConfig = state.crewConfig[crewid]
    }
    return findCrewConfig
  },
  crewEvents: (state) => (crewid) => {
    let findCrewEvents = {}
    if (Object.keys(state.events).includes(String(crewid))) {
      findCrewEvents = state.events[crewid]
    }
    return findCrewEvents
  },
}

export const actions = {
  getCrews({ commit }) {
    return new Promise((resolve, reject) => {
      return request
        .getRequest('/helper/crews')
        .then((data) => commit('addCrews', data.data))
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },
  getCrewConfig({ commit }, crewid) {
    return request.getRequest(`/crewconfig/${crewid}`).then((data) => {
      commit('addCrewConfig', data.data)
    })
  },
  getCrewEvents({ commit }, crewid) {
    return request.getRequest(`/crewconfig/${crewid}/events`).then((data) => {
      commit('addCrewEvents', data.data)
    })
  },
}
