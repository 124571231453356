export default {
    name: 'checkedVerifiedControls',
    miniSave: true,
    uploadFunction: ({ requestFunction, data, saveId }) => {
        return requestFunction
            .methodRequest(`/hazards/view/${data.hazardId}/checkoff/`, 'PUT', data)
    },
    restoreSave: (ctx) => {
    }
}
