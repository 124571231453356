<script>
export default {
  props: {
    routes: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedRoute: {
      get() {
        return this.$route.name
      },
      set(v) {
        this.$router.replace({ name: v, params: this.$route.params })
      },
    },
    allowedRoutes() {
      return this.routes.filter((route) => {
        if (route.areauuid === undefined) {
          return true
        }
        return this.$store.getters['access/checkAreaAccess'](
          route.areauuid,
          'enabled'
        )
      })
    },
    routesNoHeaders() {
      return this.allowedRoutes.filter((route) => !route.header)
    },
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown
    },
    tabletBreakpoint() {
      return this.$vuetify.breakpoint.mdAndDown
    },
  },
}
</script>

<template>
  <div>
    <v-select
      v-if="mobileBreakpoint"
      v-model="selectedRoute"
      :items="routesNoHeaders"
      append-icon="mdi-menu"
      outlined
      label="Navigation"
      item-value="link"
      item-text="name"
    />
    <v-card v-else-if="tabletBreakpoint" outlined>
      <v-card-subtitle class="pa-0 pl-4 pt-2"> Navigation </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col v-for="route in routesNoHeaders" :key="route.name" cols="3">
            <!-- <v-chip v-if="route.header" v-text="route.name" disabled /> -->
            <v-chip
              :to="{ name: route.link, params: $route.params }"
              :exact="route.exact"
              :color="route.link === $route.name ? 'primary' : null"
              replace
              label
              style="width: 100%"
              v-text="route.name.toUpperCase()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else outlined>
      <v-tabs vertical show-arrows>
        <div v-for="route in allowedRoutes" :key="route.name">
          <v-tab v-if="route.header" disabled v-text="route.name" />
          <v-tab
            v-else
            :to="{ name: route.link, params: $route.params }"
            :exact="route.exact"
            replace
            v-text="route.name"
          />
        </div>
      </v-tabs>
    </v-card>
  </div>
</template>

<style lang="scss">
.v-tabs--vertical > .v-tabs-bar .v-tab {
  justify-content: flex-start;
}
</style>
