import request from '@/tools/request'

export const state = () => ({
  contactsArr: [],
})

export const mutations = {
  addAddressBookEntries(state, contactsArr) {
    state.contactsArr = contactsArr
  },
}

export const getters = {
  contactDetails: (state) => (contactid) => {
    const findContractorCompany = state.contactsArr
      .map((contact) => {
        return {
          ...contact,
          fullname: `${contact.firstname} ${contact.lastname}`,
        }
      })
      .find((x) => String(x.id) === String(contactid))

    return findContractorCompany || {}
  },
}

export const actions = {
  getAddressBook({ commit }) {
    return new Promise((resolve, reject) => {
      return request
        .getRequest('/helper/addressbook')
        .then((data) => {
          commit('addAddressBookEntries', data.data)
        })
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },
}
