import firebase from '@/tools/firebase'

function registerMessagingToken(token) {
  console.info('registering messaging token!', token)
  const oldToken = window.localStorage.getItem('messageToken')
  const body = {
    oldToken,
    pushtoken: token,
  }
  return fetch(`${process.env.API_URL}/helper/addmessagingtoken`, {
    method: 'POST',
    body: JSON.stringify(body),
    credentials: 'include',
    mode: 'cors',
  }).then((x) => window.localStorage.setItem('messageToken', token))
}

export default async ({ store, route, app }, inject) => {
  if (process.browser) {
    if ('serviceWorker' in navigator) {
      const wb = await window.$workbox

      // Check for workbox, PushManager in window and check that user is logged in
      if (
        wb &&
        'PushManager' in window &&
        app.$cookies.get('authenticated') !== undefined
      ) {
        // Firebase Setup

        const messaging = firebase.messaging()

        navigator.serviceWorker.getRegistration().then((registration) => {
          messaging.useServiceWorker(registration)
          // window.fbMessaging = messaging;
          // Add Messaging Token
          messaging
            .requestPermission()
            .then(() => messaging.getToken())
            .then((token) => registerMessagingToken(token))
            .catch((err) => {
              console.error(
                '[sw.js] Error with registering message token.',
                err
              )
            })

          // Monitor Token Refresh
          messaging.onTokenRefresh(() => {
            messaging
              .getToken()
              .then((token) => registerMessagingToken(token))
              .catch((err) => {
                console.error(
                  '[sw.js] Error with registering message token.',
                  err
                )
              })
          })

          // Handle onMessage Event when user is using OSHTrak
          messaging.onMessage((payload) => {
            console.info('Message received. ', payload)
            if (payload.notification) {
              store.commit('notifications/addMessage', {
                message: `${payload.notification.title} - ${payload.notification.body}`,
                notification: true,
              })
            }
          })
        })
      }
    }
  }
}
