<script>
import dateFormats from '@/constants/dateFormat'
export default {
  name: 'DateTimeSelector',
  props: {
    value: {
      validator: (prop) => typeof prop === 'string' || prop === null,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: 'Date Time',
    },
    time: {
      type: Boolean,
      required: false,
      default: false,
    },
    clear: {
      type: Boolean,
      required: false,
      default: false,
    },
    minDate: {
      type: String,
      required: false,
      default: null,
    },
    maxDate: {
      type: String,
      required: false,
      default: null,
    },
    hideToday: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      dateInputField: '',
      debounceDate: this.$toolkit.debounce(this.applyCustomDate, 2000),
    }
  },
  computed: {
    dateMask() {
      return this.time ? '##/##/#### ##:##' : '##/##/####'
    },
    disableNativePicker() {
      return (
        window.localStorage.getItem('dateTimeNativePickerDisabled') === 'true'
      )
    },
    clearable() {
      return this.required === false && this.clear
    },
    dateTimeInput: {
      get() {
        return this.value
      },
      set(v) {
        const formattedDate = this.$date.formatDate(
          v,
          this.dateFormatDatefns,
          this.returnFormat
        )
        this.$emit('input', formattedDate)
      },
    },
    inputRules() {
      const rulesArr = [
        (v) =>
          v
            ? this.$date.validDate(v, this.dateFormatDatefns) ||
              'Date is not valid'
            : true,
      ]
      if (this.required) {
        rulesArr.push((v) => !!v || 'Date is required')
      }
      if (this.minDate !== null) {
        rulesArr.push(
          (v) =>
            this.$date.dateIsAfter(v, this.minDate, this.dateFormatDatefns) ||
            `Minimum Date is ${this.minDate}`
        )
      }
      if (this.maxDate !== null && this.maxDate !== this.dateTimeInput) {
        rulesArr.push(
          (v) =>
            this.$date.dateIsAfter(this.maxDate, v, this.dateFormatDatefns) ||
            `Maximum Date is ${this.maxDate}`
        )
      }
      return rulesArr
    },
    config() {
      return {
        wrap: true,
        allowInput: true,
        clickOpens: false,
        enableTime: this.time,
        dateFormat: this.dateFormat,
        minDate: this.minDateObj,
        maxDate: this.maxDateObj,
        disableMobile: this.disableNativePicker,
      }
    },
    minDateObj() {
      return this.minDate
        ? this.$date.parseDate(this.minDate, this.dateFormatDatefns)
        : null
    },
    maxDateObj() {
      return this.maxDate
        ? this.$date.parseDate(this.maxDate, this.dateFormatDatefns)
        : null
    },
    dateFormat() {
      return this.time ? 'd/m/Y H:i' : 'd/m/Y'
    },
    dateFormatDatefns() {
      return this.time ? dateFormats.datetimefn : dateFormats.datefn
    },
    returnFormat() {
      return this.format || this.dateFormatDatefns
    },
    confirmedDate() {
      return this.dateTimeInput ? this.dateTimeInput : 'No Date'
    },
    updateHint() {
      let hint = `Date Set To: ${this.confirmedDate} |`

      if (this.maxDateObj) {
        hint += ` Max date is ${this.maxDate}`
      }
      return hint
    },
  },
  watch: {
    dateInputField() {
      if (
        this.dateTimeInput !== null &&
        this.dateTimeInput.length === this.dateMask.length
      ) {
        this.debounceDate()
      }
    },
  },
  mounted() {
    this.dateInputField = this.dateTimeInput
  },
  methods: {
    applyCustomDate() {
      if (this.$date.validDate(this.dateInputField, this.dateFormatDatefns)) {
        this.dateTimeInput = this.dateInputField
      } else {
        this.dateTimeField = ''
      }
    },
    setToday() {
      this.dateTimeInput = this.$date.todayFormat(this.dateFormatDatefns)
    },
    clearDate() {
      this.$emit('input', null)
    },
  },
}
</script>

<template>
  <div class="flatpickr">
    <v-text-field
      ref="inputField"
      v-model="dateInputField"
      v-mask="dateMask"
      :rules="inputRules"
      :placeholder="time ? 'DD/MM/YYYY HH:MM' : 'DD/MM/YYYY'"
      :label="label"
      :hint="updateHint"
      :required="required"
      data-input
      persistent-hint
      outlined
      @blur="applyCustomDate"
    >
      <template #message="data">
        <span class="float-left">
          {{ data.message }}
        </span>
        <v-btn
          v-if="clearable"
          class="float-right ml-2"
          color="red"
          dark
          x-small
          @click="clearDate"
        >
          Clear
        </v-btn>
        <v-btn
          v-if="!hideToday"
          class="float-right"
          color="primary"
          outlined
          x-small
          @click="setToday"
        >
          Today
        </v-btn>
      </template>
      <template #append-outer>
        <v-icon data-toggle style="cursor: pointer"> mdi-calendar </v-icon>
      </template>
    </v-text-field>
    <flat-pickr
      v-model="dateTimeInput"
      :config="config"
      style="display: none"
      v-on="$listeners"
    />
  </div>
</template>
