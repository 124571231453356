<script>
export default {
  name: 'InternetRequired',
  props: {
    to: {
      type: String,
      required: true,
    },
    backButton: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<template>
  <div>
    <v-card-text class="text-center pt-5" style="font-weight: normal">
      <h2>Internet Connection Required</h2>
      <v-icon size="100" class="my-4"> mdi-server-network-off </v-icon>
      <h4>You must be connected to the internet to {{ to }}</h4>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="backButton" text color="primary" @click="$router.go(-1)">
        Go Back
      </v-btn>
    </v-card-actions>
  </div>
</template>
