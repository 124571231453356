const middleware = {}

middleware['getData'] = require('../middleware/getData.js')
middleware['getData'] = middleware['getData'].default || middleware['getData']

middleware['isAuthed'] = require('../middleware/isAuthed.js')
middleware['isAuthed'] = middleware['isAuthed'].default || middleware['isAuthed']

middleware['update'] = require('../middleware/update.js')
middleware['update'] = middleware['update'].default || middleware['update']

export default middleware
