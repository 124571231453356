export default {
  name: 'sopSignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const sopid = data.sopid
    const version = data.version
    const employeeid = data.employeeid
    const signature = data.signature
    const datesigned = data.datesigned
    return requestFunction
      .methodRequest(`/sop/${sopid}/${version}/signature`, 'PUT', {
        employeeid,
        signature,
        datesigned
      })
  },
  restoreSave: (ctx) => {
  }
}
