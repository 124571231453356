export default {
  name: 'sboEmail',
  miniSave: true,
  uploadFunction: ({ requestFunction, data }) => {
    return requestFunction
      .methodRequest(
        `/forms/view/${body.formId}/history/${body.formHistoryId}/email`,
        'POST',
        { addressbookid: body.addressbBookId }
      )
   },
  restoreSave: (ctx) => {}
}
