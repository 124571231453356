<script>
export default {
  props: {
    routes: {
      type: Array,
      required: false,
      default: () => [],
    },
    preventActionDrop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<template>
  <HeaderView :prevent-action-drop="preventActionDrop">
    <template #header-title>
      <slot name="header-title" />
    </template>
    <template #header-action>
      <slot name="header-action" />
    </template>
    <v-row>
      <v-col cols="12" lg="3" xl="2">
        <RouteSelector
          :routes="routes"
          :breakpoint="$vuetify.breakpoint.lgAndUp"
        />
      </v-col>
      <v-col cols="12" lg="9" xl="10">
        <slot />
      </v-col>
    </v-row>
  </HeaderView>
</template>
