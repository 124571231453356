export default {
    name: 'trainingCompetency',
    miniSave: true,
    uploadFunction: ({ requestFunction, data, saveId }) => {
        return requestFunction
            .methodRequest('/trainingcomp/', data.previousAnswer === undefined ? 'PUT' : 'POST', {
                trainingid: data.trainingid,
                employeeid: data.employeeid,
                answer: data.answer
            })
    },
    restoreSave: (ctx) => {
    }
}
