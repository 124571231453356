export default {
  name: 'signOffAction',
  // If Request is a mini Save
  miniSave: true,
  // Uploads save to server
  uploadFunction: ({ requestFunction, data }) => {
    return requestFunction
      .methodRequest(`/actions/${data.id}`, 'POST', data)
  }
}
