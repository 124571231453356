<script>
import InternetRequired from './InternetRequired'

export default {
  name: 'InternetRequiredDialog',
  components: {
    InternetRequired,
  },
  props: {
    value: {
      type: Boolean,
    },
    to: {
      type: String,
      required: true,
    },
    backButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showOfflineDialog: false,
    }
  },
  mounted() {
    this.checkConnection()
    window.addEventListener('online', this.checkConnection)
    window.addEventListener('offline', this.checkConnection)
  },
  methods: {
    checkConnection() {
      return this.$save.onlineStatus().then((onlineStatus) => {
        this.showOfflineDialog = !onlineStatus
      })
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="showOfflineDialog"
    transition="scroll-y-transition"
    no-click-animation
    internal-activator
    persistent
    max-width="500"
  >
    <v-card>
      <InternetRequired :to="to" :back-button="backButton" />
    </v-card>
  </v-dialog>
</template>
