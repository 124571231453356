<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 - Page Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    const title = this.errorTitle
    return {
      title,
    }
  },
  computed: {
    errorTitle() {
      return this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    },
  },
}
</script>

<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-card outlined class="text-center">
          <v-card-text>
            <v-icon size="100"> mdi-alert-circle </v-icon>
            <h1 v-text="errorTitle" />
            <p v-text="error.message" />
            <v-btn block to="/" color="primary"> Go To Dashboard </v-btn>
            <v-btn class="mt-2" block text @click="$router.go(-1)">
              Go Back
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
h1 {
  font-size: 20px;
}
</style>
