const inductionAnswer = {
  name: 'inductionAnswer',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/employee/induction/${data.employeeid}`, 'PUT', {
        questionid: data.questionid,
        value: data.value
      })
  },
  restoreSave: (ctx) => {
  }
};

const inductionComment = {
  name: 'inductionComment',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/employee/induction/${data.employeeid}/comments`, data.newComments ? 'PUT' : 'POST', {
        comments: data.comments,
        employeesignature: data.employeesignature,
        representativesignature: data.representativesignature,
        furthertraining: data.furthertraining,
        deemedCompetent: data.deemedCompetent
      })
  },
  restoreSave: (ctx) => {
  }
};

export default {
  inductionAnswer,
  inductionComment
}
