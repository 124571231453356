function initState() {
  return {
    appVersion: process.env.VERSION,
    accountName: '',
    companyName: '',
    companyId: null,
    employeeId: null,
    userId: null,
    userEmail: '',
    superAdmin: false,
    companyAdmin: false,
    profilePicture: null,
    demoCompany: false,
    greeting: 'DEFAULT',
    navItems: [],
    appBarExtTitle: '',
    appBarExtLink: '',
    initialDataLoaded: false,
  }
}

export const state = initState

export const mutations = {
  addAccountInfo(state, payLoad) {
    state.accountName = payLoad.accountName
    state.companyName = payLoad.companyName
    state.demoCompany = payLoad.demoCompany
    state.companyId = payLoad.companyId
    state.employeeId = payLoad.employeeId
    state.userId = payLoad.userId
    state.userEmail = payLoad.userEmail
    state.superAdmin = payLoad.superAdmin
    state.companyAdmin = payLoad.companyAdmin
    state.profilePicture = payLoad.profilePicture
    state.navItems = payLoad.navItems
  },
  dataLoaded(state) {
    state.initialDataLoaded = true
  },
  changeGreeting(state, payLoad) {
    state.greeting = payLoad
  },
  setExtAppBar(state, payLoad) {
    state.appBarExtTitle = payLoad.title
    state.appBarExtLink = payLoad.link
  },
  clearExtAppBar(state) {
    state.appBarExtTitle = ''
    state.appBarExtLink = ''
  },
  removeInfo(state) {
    const s = initState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}
