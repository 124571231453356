import extensions from '@/constants/extensions'

const allowedExtensions = extensions.filter((find) => find.api !== undefined)

export default function ({ store, app }) {
  const noAuthCookie = app.$cookies.get('authenticated') === undefined
  if (!store.state.initialDataLoaded && !noAuthCookie) {
    return store.dispatch('access/getAccess').then((response) => {
      allowedExtensions.forEach((extension) => {
        if (store.getters['access/checkIfAreaEnabled'](extension.areaUUID)) {
          store.dispatch(extension.api)
        }
      })
      return store.commit('dataLoaded')
    })
  }
}
