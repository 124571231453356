export default {
  name: 'supportQuery',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return window.$nuxt.$save
      .getAllSaves()
      .then((x) => {
        data.clientData.saveDump = x
      })
      .then(() => window.$nuxt.$save.getAllBinSaves())
      .then((x) => {
        data.clientData.binSaveDump = x
      })
      .then(() => requestFunction.methodRequest('/support', 'PUT', data))
  },
  restoreSave: (ctx) => {
  }
}
