export default {
  name: 'medicalQuestions',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/employee/medical/${data.id}`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
  ctx.router.push({ name: `employees-id-medical-information-edit`, params: { id: data.employeeid }, query: { sid: ctx.saveId } })
  }
}
