<script>
import AccessCard from '@/components/company-panel/AccessCard'

export default {
  name: 'AccessCards',
  components: {
    AccessCard,
  },
  props: {
    areas: {
      type: Array,
      required: true,
    },
    parentUuid: {
      type: String,
      default: null,
    },
    nameOfGroupUser: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchString: '',
    }
  },
  computed: {
    areaChildrenArray() {
      return this.areas.map((area) => {
        const children = this.areas.filter(
          (filterArea) => filterArea.parent_uuid === area.areauuid
        )
        return { children, ...area }
      })
    },
    areasArr() {
      return this.areaChildrenArray.filter(
        (area) => area.parent_uuid === this.parentUuid
      )
    },
    filteredAreas() {
      return this.areasArr.filter((x) =>
        x.name.toLowerCase().includes(this.searchString.toLowerCase())
      )
    },
  },
  methods: {
    applyEdit(editObj) {
      this.$emit('applyEdit', editObj)
    },
  },
}
</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-if="areas.length > 5"
        v-model="searchString"
        append-icon="mdi-magnify"
        autocomplete="off"
        label="Search Areas"
        outlined
      />
    </v-col>
    <v-col v-for="area in filteredAreas" :key="area.uuid" cols="12">
      <AccessCard
        :area="area"
        :name-of-group-user="nameOfGroupUser"
        :read-only="readOnly"
        @applyEdit="applyEdit"
      />
    </v-col>
  </v-row>
</template>
