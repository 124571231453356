export default {
  name: 'contractorVisitor',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest('/sites/visitors/contractor/', 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `sites-siteid-view-visitors`, params: { siteid: data.siteid } })
  }
}
