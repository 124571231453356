export default {
  name: 'reviewMeetingSignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/crewconfig/annualreview/meeting/${data.meetingid}/signature`, 'POST', data)
  },
  restoreSave: (ctx) => {
  }
}
