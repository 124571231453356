import request from '@/tools/request'

export const state = () => ({
  contractorsObj: {},
  contractorsArr: [],
})

export const mutations = {
  addContractors(state, contractorsObj) {
    state.contractorsObj = contractorsObj
    state.contractorsArr = Object.values(contractorsObj)
  },
}

export const getters = {
  contractorsCompanyDetails: (state) => (contractorid) => {
    const findContractorCompany = Object.values(state.contractorsObj).find(
      (x) => String(x.id) === String(contractorid)
    )
    return findContractorCompany || {}
  },
  contractorsCompanyWorkers: (state) => (contractorid) => {
    const findContractorCompany = Object.values(state.contractorsObj).find(
      (x) => String(x.id) === String(contractorid)
    )
    return (
      findContractorCompany.contractors.map((x) => ({
        fullName: `${x.firstname} ${x.lastname}`,
        ...x,
      })) || {}
    )
  },
  contractorsWorkerDetails: (state) => (workerid) => {
    const workerArr = state.contractorsArr
      .map((x) => x.contractors)
      .flat()
      .map((x) => {
        return {
          fullname: `${x.firstname} ${x.lastname}`,
          ...x,
        }
      })
    const findWorker = workerArr.find((x) => String(x.id) === String(workerid))
    return findWorker || {}
  },
}

export const actions = {
  getContractors({ commit }) {
    return new Promise((resolve, reject) => {
      return request
        .getRequest('/contractors')
        .then((data) => {
          commit('addContractors', data.data)
        })
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },
}
