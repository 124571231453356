export default {
  name: 'employeeTrainingRecord',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
    .methodRequest(
      `/employee/trainingrecords/${data.employeeid}`,
      data.id !== undefined ? 'POST' : 'PUT',
      data
    )
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `employees-id-view-training-records`, params: { id: data.employeeid }, query: { sid: ctx.saveId } })
  }
}
