export default {
  name: 'safetyMeetingSignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/crewconfig/meeting/monthly/${data.meetingid}`, 'POST', data)
  },
  restoreSave: (ctx) => {
  }
}
