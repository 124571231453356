export default {
  name: 'siteSupervisorReview',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest('/crewconfig/annualreview/review/sitesupervisor', 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `crews-crewid-safety-review-site-supervisor-review`, params: { crewid: data.crewid }, query: { sid: ctx.saveId } });
  }
}
