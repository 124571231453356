<script>
export default {
  name: 'AccessCard',
  props: {
    area: {
      type: Object,
      required: true,
    },
    nameOfGroupUser: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChildren: false,
      showEditingDialog: false,
      editingLevels: {},
    }
  },
  computed: {
    areaHasChildren() {
      return this.area.children.length > 0
    },
    accessList() {
      return this.$store.state.access.accessList
    },
    compatibleAreas() {
      const obj = this.objectMap(this.accessList, (value, key, index) => {
        return this.checkAccessValue(this.area.max_bitwise, key)
      })
      Object.keys(obj).forEach((key) =>
        obj[key] === false ? delete obj[key] : {}
      )
      return obj
    },
    buttonLayout() {
      return this.objectMap(this.compatibleAreas, (value, key, index) => {
        return this.checkAccess(this.area.allow, this.area.deny, key)
      })
    },
  },
  methods: {
    getBitwiseFromLevels(isAllow) {
      const e = Object.entries(this.editingLevels)
        .filter((x) => x[1] === isAllow)
        .map((x) => this.accessList[x[0]])

      return e.length > 0 ? e.reduce((a, b) => a + b) : null
    },
    applyEditAccess() {
      const allow = this.getBitwiseFromLevels(true)
      const deny = this.getBitwiseFromLevels(false)
      this.$emit('applyEdit', {
        areaUUID: this.area.areauuid,
        allow,
        deny,
      })
      this.showEditingDialog = false
    },
    applyChildEdit(editObj) {
      this.$emit('applyEdit', editObj)
    },
    changeValue(levelKey, newValue) {
      this.editingLevels[levelKey] = newValue
    },
    openEditingDialog() {
      this.editingLevels = { ...this.buttonLayout }
      this.showEditingDialog = true
    },
    checkAccessValue(checkWise, accessKey) {
      return !!(checkWise & this.accessList[accessKey])
    },
    checkAccess(allowValue, denyValue, accessKey) {
      if (denyValue !== null && this.checkAccessValue(denyValue, accessKey)) {
        return false
      }
      if (allowValue !== null && this.checkAccessValue(allowValue, accessKey)) {
        return true
      }
      return 'null'
    },
    objectMap(obj, fn) {
      return Object.fromEntries(
        Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
      )
    },
    getColor(x) {
      switch (x) {
        case true:
          return 'green'
        case false:
          return 'red'
        default:
          return null
      }
    },
    buttonLabel(value) {
      return value === false ? 'disabled' : 'enabled'
    },
  },
}
</script>

<template>
  <v-card class="my-1" outlined>
    <v-dialog v-model="showEditingDialog" scrollable max-width="800">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="showEditingDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Edit Access for '{{ area.name }}' for '{{ nameOfGroupUser }}'
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <div
            v-for="(value, level) in editingLevels"
            :key="level"
            class="my-3"
          >
            <h3>{{ level.toUpperCase() }}</h3>
            <v-btn-toggle :value="value" class="mt-2">
              <v-btn
                :value="true"
                :color="value === true ? 'green' : 'null'"
                large
                @change="changeValue(level, true)"
              >
                Enable
              </v-btn>
              <v-btn
                :value="false"
                :color="value === false ? 'red' : null"
                large
                @change="changeValue(level, false)"
              >
                Deny
              </v-btn>
              <v-btn :value="'null'" large @change="changeValue(level, 'null')">
                Inherit
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="showEditingDialog = false">
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn color="primary" @click="applyEditAccess"> Apply Access </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-toolbar dense> -->
    <v-row
      style="cursor: pointer"
      align="center"
      dense
      @click.stop="showChildren = !showChildren"
    >
      <v-col cols="12" md="6">
        <v-card-title v-text="area.name" />
      </v-col>
      <v-col cols="12" md="6">
        <div class="float-right ma-2 ma-md-0 mr-md-2">
          <v-btn-toggle multiple>
            <v-btn
              v-for="(value, button) in buttonLayout"
              :key="button"
              :value="value"
              :color="getColor(value)"
              :small="$vuetify.breakpoint.mobile"
              :large="!$vuetify.breakpoint.mobile"
              style="pointer-events: none"
            >
              {{ buttonLabel(value) }}
            </v-btn>
          </v-btn-toggle>
          <v-btn v-if="!readOnly" icon @click.stop="openEditingDialog">
            <v-icon> mdi-circle-edit-outline </v-icon>
          </v-btn>
          <v-btn
            v-if="areaHasChildren"
            icon
            @click.stop="showChildren = !showChildren"
          >
            <v-icon>
              {{ showChildren ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- </v-toolbar> -->
    <v-card-text v-if="areaHasChildren && showChildren">
      <AccessCards
        :areas="area.children"
        :parent-uuid="area.areauuid"
        :name-of-group-user="nameOfGroupUser"
        :read-only="readOnly"
        @applyEdit="applyChildEdit"
      />
    </v-card-text>
  </v-card>
</template>
