export const state = () => ({
  progress: 0,
  max: 0,
  status: '',
  currentPage: 'welcome',
  loadingData: false,
})

export const mutations = {
  updateStatus(state, n) {
    switch (n) {
      case 'start':
        state.status = 'Starting...'
        state.currentPage = 'download'
        break
      case 'requesting':
        state.status = 'Requesting Files...'
        break
      case 'downloading':
        state.status = 'Downloading Files...'
        break
      case 'caching':
        state.status = 'Wrapping Up...'
        break
      case 'completed':
        state.status = ''
        state.currentPage = 'complete'
        break
      default:
        state.status = 'Waiting...'
    }
  },
  addProgress(state) {
    state.progress = state.progress + 1
  },
  setMax(state, n) {
    state.max = n
  },
  changePage(state, n) {
    state.currentPage = n
  },
  loadingData(state, n) {
    state.loadingData = n
  },
  reset(state) {
    state.progress = 0
    state.max = 0
    state.status = ''
    state.currentPage = 'welcome'
  },
}
