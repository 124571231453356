import request from '@/tools/request'

export const state = () => ({
  forests: {},
})

export const mutations = {
  addForests(state, n) {
    state.forests = { ...state.forests, ...n }
  },
}

export const actions = {
  getForests({ commit }) {
    return request
      .getRequest('/sites/forests')
      .then((data) => commit('addForests', data.data))
  },
}
