<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '-NOT SET-',
    },
    desc: {
      type: String,
      required: false,
      default: '',
    },
    preventActionDrop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<template>
  <v-container fluid>
    <v-row class="module-header">
      <v-col :cols="preventActionDrop ? 8 : 12" md="8">
        <slot name="header-title">
          <h2 v-text="title" />
          <p v-if="desc" v-text="desc" />
        </slot>
      </v-col>
      <v-col :cols="preventActionDrop ? 4 : 12" md="4">
        <slot name="header-action" />
      </v-col>
    </v-row>
    <slot />
  </v-container>
</template>
