<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    titleText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fileName: '',
      newFile: null,
      fileNameExt: '',
      uploading: false,
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    newFile(e) {
      if (this.newFile !== null) {
        const split = this.newFile.name.split('.')
        this.fileNameExt = `.${split[split.length - 1]}`
      }
    },
    showDialog(v) {
      if (!v) {
        this.uploading = false
        this.newFile = null
        this.fileName = ''
      }
    },
  },
  methods: {
    uploadFile() {
      const body = {
        fileName: this.fileName,
        file: this.newFile,
        fileExt: this.fileNameExt,
      }
      this.$emit('upload', body)
      this.uploading = true
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="800"
  >
    <v-card>
      <v-toolbar>
        <v-toolbar-title> Upload File to {{ titleText }} </v-toolbar-title>
        <v-progress-linear
          :active="uploading"
          :indeterminate="uploading"
          absolute
          bottom
          color="primary accent-4"
        />
        <v-spacer />
        <v-btn icon exact @click="showDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- File Name -->
        <v-row>
          <v-col cols="12">
            <h4>File Name</h4>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="fileName"
              label="Enter File Name (eg. Safety Document)"
              outlined
              clearable
            />
          </v-col>
        </v-row>
        <!-- Upload File -->
        <v-file-input
          ref="fileinput"
          v-model="newFile"
          :disabled="uploading"
          label="Select your file"
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="newFile === null || fileName === ''"
          color="primary"
          @click="uploadFile"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
