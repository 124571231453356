import localForage from 'localforage'

import request from '@/tools/request'
import debounce from 'lodash.debounce'
import dateFormat from '@/constants/dateFormat'
import saveTypes from './saveTypes'

const VERSION = '2.0'

export class _OSHSave {
  constructor({
    saveDbName = 'savedData',
    binDbName = 'deletedSavedData',
    saveTypes,
    offlineLocalStorageFlag = 'offlineDev',
    maxBinSaves = 30,
    request,
  }) {
    // set variables
    this.version = VERSION
    this.saveTypes = saveTypes
    this.offlineLocalStorageFlag = offlineLocalStorageFlag
    this.maxBinSaves = maxBinSaves
    this.request = request
    // localForage setup
    // Set driver to IndexDB
    localForage.config({
      driver: localForage.INDEXEDDB,
    })
    // create main save db
    this.saveDb = localForage.createInstance({
      name: saveDbName,
    })
    // create save bin db
    this.saveBinDb = localForage.createInstance({
      name: binDbName,
    })
  }

  // Saves
  getSave(id) {
    return new Promise((resolve, reject) =>
      this.saveDb
        .getItem(id)
        .then((returnedItem) => resolve(returnedItem))
        .catch((err) => reject(err))
    )
  }

  // get all save with type
  getAllSavesFromType(type) {
    return new Promise((resolve, reject) => {
      const allSaves = {}
      return this.saveDb
        .iterate((value, key) => {
          const companyid = this.getCompanyId()
          if (
            typeof value.companyId === 'number' &&
            typeof companyid === 'number'
          ) {
            if (value.companyId === companyid) {
              if (type.toString() === value.type.toString()) {
                allSaves[key] = value
              }
            }
          }
        })
        .then(() => resolve(allSaves))
        .catch((err) => reject(err))
    })
  }

  // get all saves
  getAllSaves() {
    return new Promise((resolve, reject) => {
      const allSaves = {}
      return this.saveDb
        .iterate((value, key) => {
          const companyid = this.getCompanyId()
          if (
            typeof value.companyId === 'number' &&
            typeof companyid === 'number'
          ) {
            if (value.companyId === companyid) {
              allSaves[key] = value
            }
          }
        })
        .then(() => resolve(allSaves))
        .catch((err) => reject(err))
    })
  }

  // check type is in saveType array
  checkTypeExists(type) {
    return (
      type === Object.keys(this.saveTypes).find((element) => element === type)
    )
  }

  getCompanyId() {
    const companyid =
      window.localStorage.getItem('morphCompany') !== null
        ? parseInt(window.localStorage.getItem('morphCompany'), 10)
        : parseInt(window.$nuxt.$store.state.companyId, 10)
    return companyid
  }

  checkIfOtherCompanySaves() {
    let otherSavesBoolean = false
    return this.saveDb
      .iterate((value, key) => {
        const element = value
        const companyId = this.getCompanyId()
        if (
          typeof element.companyId === 'number' &&
          typeof companyId === 'number'
        ) {
          if (element.companyId !== companyId) {
            otherSavesBoolean = true
          }
        }
      })
      .then(() => Promise.resolve(otherSavesBoolean))
  }

  // remove save
  removeSave(id) {
    const removeId = typeof id === 'string' ? id : String(id)
    return new Promise((resolve, reject) =>
      this.addSaveToBin(removeId)
        .then(() => this.saveDb.removeItem(removeId))
        .then(() => window.$nuxt.$root.$emit('save::saving'))
        .then(() => resolve())
        .catch((err) => reject(err))
    )
  }

  // remove all saves
  removeAllSaves() {
    return this.getAllSaves()
      .then((saves) => Object.keys(saves))
      .then((saveKeys) =>
        Promise.all(saveKeys.map((key) => this.addSaveToBin(key)))
      )
      .then(() => this.saveDb.clear())
  }

  // purge saves past expiry
  deleteOldSaves() {
    return new Promise((resolve, reject) => {
      this.saveDb
        .iterate((value, key) => {
          if (
            29 -
              window.$nuxt.$date.countDays(
                value.saveTime,
                dateFormat.isoDateSecFn,
                window.$nuxt.$date.todayFormat(dateFormat.isoDateSecFn),
                dateFormat.isoDateSecFn
              ) <=
            0
          ) {
            this.removeSave(key)
          }
        })
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  }

  // Uploads
  // get all uploads
  getUploads() {
    return new Promise((resolve, reject) => {
      const allUploads = {}
      const companyid = this.getCompanyId()
      return this.saveDb
        .iterate((value, key) => {
          if (value.uploadable) {
            if (
              typeof value.companyId === 'number' &&
              typeof companyid === 'number' &&
              value.companyId === companyid
            ) {
              allUploads[key] = value
            }
          }
        })
        .then(() => resolve(allUploads))
        .catch((err) => reject(err))
    })
  }

  // Bin
  // get all saves in bin
  getAllBinSaves() {
    return new Promise((resolve, reject) => {
      const allSaves = {}
      return this.saveBinDb
        .iterate((value, key) => {
          allSaves[key] = value
        })
        .then(() => resolve(allSaves))
        .catch((err) => reject(err))
    })
  }

  // add save to bin
  addSaveToBin(id) {
    const binKeys = []
    const MAX_SAVES = 3
    const deletedon = window.$nuxt.$date.todayFormat(dateFormat.isoDateSecFn)
    return (
      this.saveBinDb
        // Get all bin keys
        .iterate((value, key) => {
          const saveDeletedOn = value !== undefined ? value.deletedon : null
          binKeys.push([key, saveDeletedOn])
        })
        // Sort array by deleted time
        .then(() =>
          binKeys.sort((a, b) => {
            const aDeletedOn = a[1]
            const bDeletedOn = b[1]
            if (a[1] === null) {
              return 1
            }
            if (b[2] === null) {
              return 1
            }

            return window.$nuxt.$date.compareDatesDesc(
              aDeletedOn,
              dateFormat.isoDateSecFn,
              bDeletedOn,
              dateFormat.isoDateSecFn
            )
          })
        )
        // see if length of key arr is above MAX_SAVES
        .then((sortedArr) =>
          sortedArr.length > MAX_SAVES
            ? sortedArr.slice(0, sortedArr.length - 1 - MAX_SAVES)
            : []
        )
        // delete any saves over limit
        .then((deleteKeysArr) =>
          Promise.all(
            deleteKeysArr.map((keyArr) => this.saveBinDb.removeItem(keyArr[0]))
          )
        )
        // get save to add
        .then(() => this.getSave(id))
        // add to bin
        .then((saveValue) =>
          this.saveBinDb.setItem(id, { deletedon, ...saveValue })
        )
    )
  }

  // Connection checks
  // check if connection to server is possible
  _checkConnectionToServer() {
    const TIMEOUT = 5
    const API_URL = process.env.API_URL || 'https://api.oshtrak.com'
    // global fetch
    const req = window.fetch(`${API_URL}/ping?d=${Date.now()}`)

    const timeout = new Promise((resolve, reject) =>
      setTimeout(() => reject(), TIMEOUT * 1000)
    )
    return Promise.race([req, timeout])
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false))
  }

  // get online status
  async onlineStatus() {
    // check if set to dev mode
    if (window.localStorage.getItem(this.offlineLocalStorageFlag) === 'true') {
      return Promise.resolve(false)
    }
    // check if navigator.onLine is false (offline)
    if (!navigator.onLine) {
      return Promise.resolve(false)
    }
    // check connection to server
    const connectToServer = await this._checkConnectionToServer()
    return connectToServer
  }

  // tools
  // import save dump
  importSaves(saves) {
    Object.keys(saves).forEach((key) => {
      const data = saves[key]
      this.saveDb.setItem(key, data)
    })
  }

  /**
   * V2
   *
   */
  requestPersistentStorage() {
    if (navigator.storage && navigator.storage.persist) {
      return navigator.storage.persist()
    }
  }

  // generate save id
  generateId() {
    // todo check if in use
    const id = Math.random().toString(36).substr(2, 5)
    return id
  }

  // getType
  getType(type) {
    return this.checkTypeExists(type) ? this.saveTypes[type] : null
  }

  // Add Save To Internal Database
  _commitSave({ id, type, data, uploadable, desc = '' }) {
    this.requestPersistentStorage()
    const saveTime = window.$nuxt.$date.todayFormat(dateFormat.isoDateSecFn)
    const companyId = this.getCompanyId()
    const typeObj = this.getType(type)

    if (typeObj !== null) {
      return this.saveDb
        .setItem(id, {
          version: this.version,
          saveTime,
          mini: typeObj.miniSave,
          desc,
          companyId,
          type,
          data,
          uploadable,
        })
        .then(() => {
          window.$nuxt.$root.$emit('save::saving')
          if (uploadable) {
            // this.createUploadReminderNotification();
            // TODO Notification upload save when connected to internet
          }
        })
        .catch((err) => Promise.reject(err))
    }
    return Promise.reject(`Type '${type}' cannot be found`)
  }

  commitSave = debounce(this._commitSave, 500, {
    leading: false,
    trailing: true,
  })

  // Used when submitting a form, will either save data or upload it depending if online or offline
  async submitData({ type, id, desc, data, success, error }) {
    const online = await this.onlineStatus()
    if (online) {
      return (
        this._uploadType({ type, data })
          // true because online
          .then((data) => success(true, data))
          .then(() => this.removeSave(id))
          .catch((err) => error(err))
      )
    } else {
      this.commitSave({ type, id, desc, data, uploadable: true })
      success(false)
    }
  }

  // saves the data
  // runs upload function from save type
  _uploadType({ type, data }) {
    return this.saveTypes[type].uploadFunction({
      requestFunction: request,
      data,
    })
  }

  // upload a save
  uploadSavev2(saveId) {
    return new Promise((resolve, reject) =>
      this.getSave(saveId)
        .then((value) => {
          // this.cancelUploadReminderNotification();
          return this._uploadType({ type: value.type, data: value.data })
        })
        .then(() => this.removeSave(saveId))
        .then(() => resolve())
        .catch((err) => reject(err))
    )
  }

  uploadAllSaves() {
    return this.getUploads().then((saveObj) =>
      Promise.all(Object.keys(saveObj).map((id) => this.uploadSavev2(id)))
    )
  }

  restoreSave(saveId) {
    const router = window.$nuxt.$router
    return this.getSave(saveId).then((saveObj) => {
      const typeObj = this.getType(saveObj.type)
      if (typeObj !== null) {
        return typeObj.restoreSave({ router, saveObj, saveId })
      }
      return Promise.reject('No Type Found')
    })
  }

  dumpSavesToServer() {
    return new Promise((resolve, reject) => {
      const objOfData = {}
      const objOfBinData = {}
      this.saveDb
        .iterate((value, key) => {
          objOfData[key] = value
        })
        .then(() =>
          this.saveBinDb.iterate((value, key) => {
            objOfBinData[key] = value
          })
        )
        .then(() =>
          request.methodRequest('/helper/savedata', 'POST', {
            saveData: objOfData,
            binData: objOfBinData,
          })
        )
        .then((x) => resolve(x.data.id))
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }
}

const save = new _OSHSave({
  saveTypes,
  request,
})

export default save
