export default {
  name: 'preSite',
  miniSave: false,
  uploadFunction: ({ requestFunction, data}) => {
    return requestFunction
      .methodRequest(`/sites/presite/${data.siteid}`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: 'sites-siteid-presite-new', params: { siteid: data.siteid }, query: { sid: ctx.saveId } });
  }
}
