<script>
import LoadingComponent from './LoadingComponent'

export default {
  components: {
    LoadingComponent,
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
</script>

<template>
  <v-fade-transition leave-absolute>
    <LoadingComponent v-if="loading" :full-page="true" />
  </v-fade-transition>
</template>
