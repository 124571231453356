export default async ({ store, route, app }, inject) => {
  if (process.browser) {
    if ('serviceWorker' in navigator) {
      const workbox = await window.$workbox

      if (workbox) {
        const waitingEvent = (e) => {
          console.info('waiting', e)
          workbox.addEventListener('controlling', (event) => {
            console.info('controling', event)
            window.location.reload()
          })
          store.commit('app/appUpdateAvailable', true)
        }
        workbox.addEventListener('waiting', waitingEvent)
        workbox.addEventListener('externalwaiting', waitingEvent)

        workbox.addEventListener('activated', () => {
          window.location.reload()
        })

        inject('updateApp', () => {
          navigator.serviceWorker.ready.then((registration) => {
            if (registration.waiting === null) {
              return window.location.reload()
            }
            return registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          })
        })
      }
    }
  }
}
