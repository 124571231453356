<script>
export default {
  name: 'CovidTracker',
  data() {
    return {
      covidString: '- Loading -',
    }
  },
  methods: {
    openLevel2Doc() {
      window.open('/pdf/covid2.pdf', '_blank', 'toolbar=yes')
    },
    openLevel3Doc() {
      window.open('/pdf/covid3.pdf', '_blank', 'toolbar=yes')
    },
  },
}
</script>

<template>
  <v-card class="covid mb-1" outlined>
    <v-toolbar dense>
      <v-btn icon disabled>
        <v-icon>mdi-shield-bug-outline</v-icon>
      </v-btn>
      <v-toolbar-title>COVID-19</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          href="https://tracing.covid19.govt.nz/"
          target="_blank"
          rel="noopener"
          text
        >
          Tracer App
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text class="overlay py-1">
      <v-row align="center">
        <v-col cols="12">
          <h4 class="white--text">
            Get the latest information regarding COVID-19 at
            <a href="https://covid19.govt.nz" target="_blank"
              >covid19.govt.nz</a
            >
          </h4>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-btn block outlined color="primary" @click="openLevel2Doc">
            Level 2 Protocols
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn block color="primary" @click="openLevel3Doc">
            Level 3 & 4 Protocols
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.covid {
  background-image: url('~@/assets/covid.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 0;

  .overlay {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>
