export default {
    name: 'safeStartPlan',
    miniSave: false,
    uploadFunction: ({ requestFunction, data, saveId }) => {
        return requestFunction
            .methodRequest(`/crewconfig/safestartplan/`, 'PUT', data)
    },
    restoreSave: (ctx) => {
        const data = ctx.saveObj.data;
        ctx.router.push({ name: `crews-crewid-safe-start-stop-new`, params: { crewid: data.crewid }, query: { sid: ctx.saveId } })
    }
}
