export default {
  name: 'envChecklist',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const siteId = data.siteId
    delete data.siteId
    return requestFunction
      .methodRequest(`/sites/envchecklist/${siteId}`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `sites-siteid-enviromentalchecklist-new`, params: { siteid: data.siteId }, query: { sid: ctx.saveId, crewid: data.crewId } })
  }
}
