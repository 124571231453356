export default {
  name: 'siteEmergency',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const siteId = data.siteId
    delete data.siteId
    return requestFunction
      .methodRequest(`/sites/emergency/${siteId}`, 'POST', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `sites-siteid-emergency-information-edit`, params: { siteid: data.siteId }, query: { sid: ctx.saveId } })
  }
}
