const healthCheck = {
  name: 'employeeComplianceHealthCheck',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/employee/compliance/healthcheck/${data.employeeid}`,
      'PUT',
      {
        completedHealthCheckOn: data.completedHealthCheckOn,
        nextHealthCheckDueOn: data.nextHealthCheckDueOn,
      }
    )
  },
  restoreSave: (ctx) => {},
}

const firstAid = {
  name: 'employeeComplianceFirstAid',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/employee/compliance/firstaid/${data.employeeid}`,
      'PUT',
      {
        completedFirstAidOn: data.completedFirstAidOn,
        nextFirstAidDueOn: data.nextFirstAidDueOn,
      }
    )
  },
  restoreSave: (ctx) => {},
}

const faller = {
  name: 'employeeComplianceFaller',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/employee/compliance/faller/${data.employeeid}`,
      'PUT',
      {
        completedTrainingOn: data.completedTrainingOn,
        nextTrainingDue: data.nextTrainingDue,
      }
    )
  },
  restoreSave: (ctx) => {},
}

const breakerOut = {
  name: 'employeeComplianceBreakerOut',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/employee/compliance/breakerout/${data.employeeid}`,
      'PUT',
      {
        completedTrainingOn: data.completedTrainingOn,
        nextTrainingDue: data.nextTrainingDue,
      }
    )
  },
  restoreSave: (ctx) => {},
}

const emergencyWardenTraining = {
  name: 'employeeComplianceEmergencyWardenTraining',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction.methodRequest(
      `/employee/compliance/emergencywarden/${data.employeeid}`,
      'PUT',
      {
        completedTrainingOn: data.completedTrainingOn,
        nextTrainingDue: data.nextTrainingDue,
      }
    )
  },
  restoreSave: (ctx) => {},
}

export default {
  healthCheck,
  firstAid,
  emergencyWardenTraining,
  faller,
  breakerOut
}
