<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: 'Update and submit the form below to update the reminder',
    },
  },
}
</script>

<template>
  <v-alert color="info" icon="mdi-alert">
    {{ message }}
  </v-alert>
</template>
