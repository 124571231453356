export default {
  name: 'toolbox',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/forms/view/${data.toolboxId}`, 'PUT', data)
   },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data
    ctx.router.push({ name: `crews-crewid-toolbox-new-toolboxid`, params: { toolboxid: data.toolboxId, crewid: data.crewId }, query: { sid: ctx.saveId}})
  }
}
