export default {
    name: 'siteCovidCode',
    miniSave: true,
    uploadFunction: ({ requestFunction, data, saveId }) => {
      const siteId = data.siteId
      delete data.siteId
      return requestFunction
        .methodRequest(`/sites/covidcode/${siteId}`, 'PUT', data)
    },
    restoreSave: (ctx) => {
    }
  }