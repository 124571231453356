export default {
  name: 'machinePrePurchase',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/register/machine/prepurchase/${data.machineid}`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `machines-machineid-prepurchase-new`, params: { machineid: data.machineid }, query: { sid: ctx.saveId } })
  }
}
