export default {
  name: 'systemsAudit',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/crewconfig/quarterlyaudit/${data.crewid}`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `crews-crewid-systems-audit-new`, params: { crewid: data.crewid }, query: { sid: ctx.saveId } })
  }
}
