import OSHRequest from '@oshtrak/osh-request'
let store

window.onNuxtReady(({ $store }) => {
  store = $store
})

function handleErrors(response, request) {
  if (!response.ok) {
    return response
      .json()
      .then((x) => {
        if (x.logout) {
          const passportURL =
            process.env.PASSPORT_URL || 'https://passport.oshtrak.com'
          window.location.assign(`${passportURL}/logout`)
        }
        if (x.redirectToPassport) {
          const passportURL =
            process.env.PASSPORT_URL || 'https://passport.oshtrak.com'
          window.location.assign(`${passportURL}?noaccess=true`)
        }
        store.commit('notifications/addMessage', { message: x.message })
        return Promise.reject(x)
      })
      .catch((e) =>
        store.commit('notifications/addMessage', {
          message: 'Cannot read request error',
        })
      )
  }
  return response
}

const headers = {}

if (window.localStorage.getItem('morphCompany')) {
  headers['Morph-Company'] = window.localStorage.getItem('morphCompany')
}

const request = new OSHRequest({
  apiURL: process.env.API_URL,
  version: process.env.VERSION || 0,
  errorHandlerFunction: handleErrors,
  headers,
})

export default request
