export default {
  name: 'newHazard',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/hazards/view/${data.hazardidId}/addhazard/`, 'PUT', data.hazardData)
  },
  restoreSave: (ctx) => {
  }
}
