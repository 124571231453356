import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25ffe3e8 = () => interopDefault(import('../pages/address-book/index.vue' /* webpackChunkName: "pages/address-book/index" */))
const _5b3ab4ae = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _16494f40 = () => interopDefault(import('../pages/company-panel/index.vue' /* webpackChunkName: "pages/company-panel/index" */))
const _3c42100a = () => interopDefault(import('../pages/contractors/index.vue' /* webpackChunkName: "pages/contractors/index" */))
const _52c71e5e = () => interopDefault(import('../pages/crews/index.vue' /* webpackChunkName: "pages/crews/index" */))
const _58f1de71 = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _13c3ae2c = () => interopDefault(import('../pages/hazard-register/index.vue' /* webpackChunkName: "pages/hazard-register/index" */))
const _29cbfc66 = () => interopDefault(import('../pages/incidents/index.vue' /* webpackChunkName: "pages/incidents/index" */))
const _25bae0d2 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _bde94614 = () => interopDefault(import('../pages/machines/index.vue' /* webpackChunkName: "pages/machines/index" */))
const _db61fba6 = () => interopDefault(import('../pages/reminders/index.vue' /* webpackChunkName: "pages/reminders/index" */))
const _3543cfc4 = () => interopDefault(import('../pages/safety-alerts/index.vue' /* webpackChunkName: "pages/safety-alerts/index" */))
const _810bb966 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _6ee16cb8 = () => interopDefault(import('../pages/sites/index.vue' /* webpackChunkName: "pages/sites/index" */))
const _4c01a24f = () => interopDefault(import('../pages/summary.vue' /* webpackChunkName: "pages/summary" */))
const _c0ce1cdc = () => interopDefault(import('../pages/summary/index.vue' /* webpackChunkName: "pages/summary/index" */))
const _18da0bf2 = () => interopDefault(import('../pages/summary/crews.vue' /* webpackChunkName: "pages/summary/crews" */))
const _9fffa9be = () => interopDefault(import('../pages/summary/incidents.vue' /* webpackChunkName: "pages/summary/incidents" */))
const _24c33df8 = () => interopDefault(import('../pages/support.vue' /* webpackChunkName: "pages/support" */))
const _aa4f3ac8 = () => interopDefault(import('../pages/training-competency/index.vue' /* webpackChunkName: "pages/training-competency/index" */))
const _664fc0f1 = () => interopDefault(import('../pages/vehicles/index.vue' /* webpackChunkName: "pages/vehicles/index" */))
const _612e4607 = () => interopDefault(import('../pages/company-panel/view.vue' /* webpackChunkName: "pages/company-panel/view" */))
const _7e0c296c = () => interopDefault(import('../pages/company-panel/view/index.vue' /* webpackChunkName: "pages/company-panel/view/index" */))
const _2604efb7 = () => interopDefault(import('../pages/company-panel/view/edit-details.vue' /* webpackChunkName: "pages/company-panel/view/edit-details" */))
const _63612fc7 = () => interopDefault(import('../pages/company-panel/view/manage-access/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/index" */))
const _0a457d82 = () => interopDefault(import('../pages/company-panel/view/manage-crews.vue' /* webpackChunkName: "pages/company-panel/view/manage-crews" */))
const _65cb7c55 = () => interopDefault(import('../pages/company-panel/view/manage-employees.vue' /* webpackChunkName: "pages/company-panel/view/manage-employees" */))
const _1a6c65c0 = () => interopDefault(import('../pages/company-panel/view/manage-access/view.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view" */))
const _2f459c43 = () => interopDefault(import('../pages/company-panel/view/manage-access/view/index.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/index" */))
const _45fb789a = () => interopDefault(import('../pages/company-panel/view/manage-access/view/groups.vue' /* webpackChunkName: "pages/company-panel/view/manage-access/view/groups" */))
const _6853ab9e = () => interopDefault(import('../pages/company/view.vue' /* webpackChunkName: "pages/company/view" */))
const _218c83a1 = () => interopDefault(import('../pages/company/view/index.vue' /* webpackChunkName: "pages/company/view/index" */))
const _0444bac6 = () => interopDefault(import('../pages/company/view/files.vue' /* webpackChunkName: "pages/company/view/files" */))
const _54542d1b = () => interopDefault(import('../pages/company/view/objectives.vue' /* webpackChunkName: "pages/company/view/objectives" */))
const _45173473 = () => interopDefault(import('../pages/company/view/policy.vue' /* webpackChunkName: "pages/company/view/policy" */))
const _7166c402 = () => interopDefault(import('../pages/employees/new.vue' /* webpackChunkName: "pages/employees/new" */))
const _689de85b = () => interopDefault(import('../pages/incidents/new.vue' /* webpackChunkName: "pages/incidents/new" */))
const _17dc6045 = () => interopDefault(import('../pages/machines/add.vue' /* webpackChunkName: "pages/machines/add" */))
const _52354228 = () => interopDefault(import('../pages/safety-alerts/new.vue' /* webpackChunkName: "pages/safety-alerts/new" */))
const _57507aa6 = () => interopDefault(import('../pages/sites/register.vue' /* webpackChunkName: "pages/sites/register" */))
const _4360d370 = () => interopDefault(import('../pages/sites/register/index.vue' /* webpackChunkName: "pages/sites/register/index" */))
const _13a91716 = () => interopDefault(import('../pages/sites/register/_forestid.vue' /* webpackChunkName: "pages/sites/register/_forestid" */))
const _6d4f8700 = () => interopDefault(import('../pages/vehicles/add.vue' /* webpackChunkName: "pages/vehicles/add" */))
const _4795aa12 = () => interopDefault(import('../pages/reminders/goto/crewgear.vue' /* webpackChunkName: "pages/reminders/goto/crewgear" */))
const _33872bc4 = () => interopDefault(import('../pages/reminders/goto/employeegear.vue' /* webpackChunkName: "pages/reminders/goto/employeegear" */))
const _c64f73e0 = () => interopDefault(import('../pages/company-panel/manage-access/group/_groupuuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/group/_groupuuid" */))
const _3b2a81a6 = () => interopDefault(import('../pages/company-panel/manage-access/user/_useruuid.vue' /* webpackChunkName: "pages/company-panel/manage-access/user/_useruuid" */))
const _2065fcca = () => interopDefault(import('../pages/company/program/_versionid/index.vue' /* webpackChunkName: "pages/company/program/_versionid/index" */))
const _3ee675b9 = () => interopDefault(import('../pages/company/program/_versionid/policy.vue' /* webpackChunkName: "pages/company/program/_versionid/policy" */))
const _2de458dc = () => interopDefault(import('../pages/employees/_id/index.vue' /* webpackChunkName: "pages/employees/_id/index" */))
const _5e536e38 = () => interopDefault(import('../pages/incidents/_id/index.vue' /* webpackChunkName: "pages/incidents/_id/index" */))
const _69818c2c = () => interopDefault(import('../pages/sites/_siteid/index.vue' /* webpackChunkName: "pages/sites/_siteid/index" */))
const _31f801d5 = () => interopDefault(import('../pages/summarynew/_drillid.vue' /* webpackChunkName: "pages/summarynew/_drillid" */))
const _f293ce62 = () => interopDefault(import('../pages/contractors/_companyid/agreement/index.vue' /* webpackChunkName: "pages/contractors/_companyid/agreement/index" */))
const _2de91c20 = () => interopDefault(import('../pages/contractors/_companyid/reviews/index.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/index" */))
const _0b93e0e0 = () => interopDefault(import('../pages/crews/_crewid/documents.vue' /* webpackChunkName: "pages/crews/_crewid/documents" */))
const _4c7caa13 = () => interopDefault(import('../pages/crews/_crewid/documents/index.vue' /* webpackChunkName: "pages/crews/_crewid/documents/index" */))
const _2f34e138 = () => interopDefault(import('../pages/crews/_crewid/documents/files.vue' /* webpackChunkName: "pages/crews/_crewid/documents/files" */))
const _72301a6e = () => interopDefault(import('../pages/crews/_crewid/documents/gear-tracker.vue' /* webpackChunkName: "pages/crews/_crewid/documents/gear-tracker" */))
const _a2f2b49e = () => interopDefault(import('../pages/crews/_crewid/documents/safe-start.vue' /* webpackChunkName: "pages/crews/_crewid/documents/safe-start" */))
const _2ca6f155 = () => interopDefault(import('../pages/crews/_crewid/documents/substance-inv.vue' /* webpackChunkName: "pages/crews/_crewid/documents/substance-inv" */))
const _ce6bab36 = () => interopDefault(import('../pages/crews/_crewid/monthly.vue' /* webpackChunkName: "pages/crews/_crewid/monthly" */))
const _11a49128 = () => interopDefault(import('../pages/crews/_crewid/monthly/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/index" */))
const _5ef23f92 = () => interopDefault(import('../pages/crews/_crewid/monthly/audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/audits" */))
const _31ed2b64 = () => interopDefault(import('../pages/crews/_crewid/monthly/emergency-drills.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/emergency-drills" */))
const _1f702eee = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-meetings.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-meetings" */))
const _056f76ee = () => interopDefault(import('../pages/crews/_crewid/monthly/safety-review.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/safety-review" */))
const _345fc70d = () => interopDefault(import('../pages/crews/_crewid/monthly/sbos.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/sbos" */))
const _7008bdac = () => interopDefault(import('../pages/crews/_crewid/monthly/system-audits.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/system-audits" */))
const _be28bd68 = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/index.vue' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/index" */))
const _5c3a499a = () => interopDefault(import('../pages/crews/_crewid/monthly/toolbox/toolbox.unit.js' /* webpackChunkName: "pages/crews/_crewid/monthly/toolbox/toolbox.unit" */))
const _7b3b3ef0 = () => interopDefault(import('../pages/employees/_id/edit.vue' /* webpackChunkName: "pages/employees/_id/edit" */))
const _6305460b = () => interopDefault(import('../pages/employees/_id/view.vue' /* webpackChunkName: "pages/employees/_id/view" */))
const _cdac4f64 = () => interopDefault(import('../pages/employees/_id/view/index.vue' /* webpackChunkName: "pages/employees/_id/view/index" */))
const _34f39462 = () => interopDefault(import('../pages/employees/_id/view/compliance.vue' /* webpackChunkName: "pages/employees/_id/view/compliance" */))
const _8fc034d6 = () => interopDefault(import('../pages/employees/_id/view/drug-alcohol.vue' /* webpackChunkName: "pages/employees/_id/view/drug-alcohol" */))
const _7be20f73 = () => interopDefault(import('../pages/employees/_id/view/files.vue' /* webpackChunkName: "pages/employees/_id/view/files" */))
const _3635452b = () => interopDefault(import('../pages/employees/_id/view/forms.vue' /* webpackChunkName: "pages/employees/_id/view/forms" */))
const _e406a586 = () => interopDefault(import('../pages/employees/_id/view/induction.vue' /* webpackChunkName: "pages/employees/_id/view/induction" */))
const _9b96aa66 = () => interopDefault(import('../pages/employees/_id/view/linked.vue' /* webpackChunkName: "pages/employees/_id/view/linked" */))
const _520ee98d = () => interopDefault(import('../pages/employees/_id/view/medical.vue' /* webpackChunkName: "pages/employees/_id/view/medical" */))
const _37893d4c = () => interopDefault(import('../pages/employees/_id/view/ppe-tracker.vue' /* webpackChunkName: "pages/employees/_id/view/ppe-tracker" */))
const _0d4c1396 = () => interopDefault(import('../pages/employees/_id/view/record-learning.vue' /* webpackChunkName: "pages/employees/_id/view/record-learning" */))
const _0ad9f703 = () => interopDefault(import('../pages/employees/_id/view/training-records.vue' /* webpackChunkName: "pages/employees/_id/view/training-records" */))
const _2fb3e3d8 = () => interopDefault(import('../pages/incidents/_id/edit.vue' /* webpackChunkName: "pages/incidents/_id/edit" */))
const _ab366ccc = () => interopDefault(import('../pages/machines/_machineid/edit.vue' /* webpackChunkName: "pages/machines/_machineid/edit" */))
const _72b74ad2 = () => interopDefault(import('../pages/machines/_machineid/files.vue' /* webpackChunkName: "pages/machines/_machineid/files" */))
const _d30d7fb8 = () => interopDefault(import('../pages/machines/_machineid/induction/index.vue' /* webpackChunkName: "pages/machines/_machineid/induction/index" */))
const _9f70f872 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/index.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/index" */))
const _dd39e986 = () => interopDefault(import('../pages/sites/_siteid/view.vue' /* webpackChunkName: "pages/sites/_siteid/view" */))
const _4e577000 = () => interopDefault(import('../pages/sites/_siteid/view/index.vue' /* webpackChunkName: "pages/sites/_siteid/view/index" */))
const _3ae19d8b = () => interopDefault(import('../pages/sites/_siteid/view/covid-qr.vue' /* webpackChunkName: "pages/sites/_siteid/view/covid-qr" */))
const _1338971e = () => interopDefault(import('../pages/sites/_siteid/view/emergency-information.vue' /* webpackChunkName: "pages/sites/_siteid/view/emergency-information" */))
const _310fa725 = () => interopDefault(import('../pages/sites/_siteid/view/files.vue' /* webpackChunkName: "pages/sites/_siteid/view/files" */))
const _62e506e4 = () => interopDefault(import('../pages/sites/_siteid/view/hazard-id.vue' /* webpackChunkName: "pages/sites/_siteid/view/hazard-id" */))
const _e7a9f20e = () => interopDefault(import('../pages/sites/_siteid/view/more-forms.vue' /* webpackChunkName: "pages/sites/_siteid/view/more-forms" */))
const _d3ed36f2 = () => interopDefault(import('../pages/sites/_siteid/view/visitors.vue' /* webpackChunkName: "pages/sites/_siteid/view/visitors" */))
const _a5f190f4 = () => interopDefault(import('../pages/sites/_siteid/visitor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor" */))
const _725cfcee = () => interopDefault(import('../pages/sites/_siteid/visitor/index.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/index" */))
const _2f57f658 = () => interopDefault(import('../pages/sites/_siteid/visitor/addressbook.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/addressbook" */))
const _f5be6264 = () => interopDefault(import('../pages/sites/_siteid/visitor/contractor.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/contractor" */))
const _ec75af68 = () => interopDefault(import('../pages/sites/_siteid/visitor/infrequent.vue' /* webpackChunkName: "pages/sites/_siteid/visitor/infrequent" */))
const _456dd860 = () => interopDefault(import('../pages/vehicles/_vehicleid/edit.vue' /* webpackChunkName: "pages/vehicles/_vehicleid/edit" */))
const _b1f50924 = () => interopDefault(import('../pages/contractors/_companyid/reviews/new.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/new" */))
const _32eed2e1 = () => interopDefault(import('../pages/crews/_crewid/audits/new.vue' /* webpackChunkName: "pages/crews/_crewid/audits/new" */))
const _157a5cf0 = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/new.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/new" */))
const _57c59194 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/new.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/new" */))
const _71b4c529 = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/new" */))
const _6235758b = () => interopDefault(import('../pages/crews/_crewid/safety-review/health-representative-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/health-representative-review" */))
const _76759f1c = () => interopDefault(import('../pages/crews/_crewid/safety-review/new.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/new" */))
const _0a532e0b = () => interopDefault(import('../pages/crews/_crewid/safety-review/site-supervisor-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/site-supervisor-review" */))
const _7cd6bcfa = () => interopDefault(import('../pages/crews/_crewid/safety-review/worker-review.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/worker-review" */))
const _cb6ffd8e = () => interopDefault(import('../pages/crews/_crewid/safety-sheets/add.vue' /* webpackChunkName: "pages/crews/_crewid/safety-sheets/add" */))
const _1b35134a = () => interopDefault(import('../pages/crews/_crewid/systems-audit/new.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/new" */))
const _455a0090 = () => interopDefault(import('../pages/employees/_id/forms/da-consent.vue' /* webpackChunkName: "pages/employees/_id/forms/da-consent" */))
const _605c783b = () => interopDefault(import('../pages/employees/_id/forms/rol-request.vue' /* webpackChunkName: "pages/employees/_id/forms/rol-request" */))
const _5dee8b2f = () => interopDefault(import('../pages/employees/_id/medical-information/edit.vue' /* webpackChunkName: "pages/employees/_id/medical-information/edit" */))
const _5b715e24 = () => interopDefault(import('../pages/employees/_id/record-learning/request.vue' /* webpackChunkName: "pages/employees/_id/record-learning/request" */))
const _34484ea8 = () => interopDefault(import('../pages/machines/_machineid/induction/edit.vue' /* webpackChunkName: "pages/machines/_machineid/induction/edit" */))
const _7557a0d5 = () => interopDefault(import('../pages/machines/_machineid/prepurchase/new.vue' /* webpackChunkName: "pages/machines/_machineid/prepurchase/new" */))
const _b3624f3e = () => interopDefault(import('../pages/sites/_siteid/emergency-information/edit.vue' /* webpackChunkName: "pages/sites/_siteid/emergency-information/edit" */))
const _3bcd8626 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/new.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/new" */))
const _aac2b54a = () => interopDefault(import('../pages/sites/_siteid/hazard-id/edit.vue' /* webpackChunkName: "pages/sites/_siteid/hazard-id/edit" */))
const _75f9bf13 = () => interopDefault(import('../pages/sites/_siteid/presite/new.vue' /* webpackChunkName: "pages/sites/_siteid/presite/new" */))
const _316b9bcb = () => interopDefault(import('../pages/crews/_crewid/sbos/new/_formid.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/new/_formid" */))
const _59bb8792 = () => interopDefault(import('../pages/crews/_crewid/toolbox/new/_toolboxid.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/new/_toolboxid" */))
const _42a8f99a = () => interopDefault(import('../pages/crews/_crewid/sbos/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/sbos/view/_formid/_id" */))
const _b3ef041a = () => interopDefault(import('../pages/crews/_crewid/toolbox/view/_formid/_id.vue' /* webpackChunkName: "pages/crews/_crewid/toolbox/view/_formid/_id" */))
const _d960b2f0 = () => interopDefault(import('../pages/contractors/_companyid/reviews/_id.vue' /* webpackChunkName: "pages/contractors/_companyid/reviews/_id" */))
const _1f38fdfb = () => interopDefault(import('../pages/crews/_crewid/audits/_id.vue' /* webpackChunkName: "pages/crews/_crewid/audits/_id" */))
const _107b4d3f = () => interopDefault(import('../pages/crews/_crewid/emergency-drill/_drillid.vue' /* webpackChunkName: "pages/crews/_crewid/emergency-drill/_drillid" */))
const _7f313b60 = () => interopDefault(import('../pages/crews/_crewid/safe-start-stop/_id.vue' /* webpackChunkName: "pages/crews/_crewid/safe-start-stop/_id" */))
const _5dab041e = () => interopDefault(import('../pages/crews/_crewid/safety-meeting/_meetingid.vue' /* webpackChunkName: "pages/crews/_crewid/safety-meeting/_meetingid" */))
const _42a0bd16 = () => interopDefault(import('../pages/crews/_crewid/systems-audit/_id.vue' /* webpackChunkName: "pages/crews/_crewid/systems-audit/_id" */))
const _63392ff2 = () => interopDefault(import('../pages/sites/_siteid/enviromentalchecklist/_id.vue' /* webpackChunkName: "pages/sites/_siteid/enviromentalchecklist/_id" */))
const _6243ea2d = () => interopDefault(import('../pages/sites/_siteid/presite/_id.vue' /* webpackChunkName: "pages/sites/_siteid/presite/_id" */))
const _f279453c = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting" */))
const _50c787cf = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/meeting.unit.js' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/meeting.unit" */))
const _0bd47400 = () => interopDefault(import('../pages/crews/_crewid/safety-review/_id/_type.vue' /* webpackChunkName: "pages/crews/_crewid/safety-review/_id/_type" */))
const _0fa17802 = () => interopDefault(import('../pages/contractors/_companyid/_id/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/index" */))
const _0c14d1e0 = () => interopDefault(import('../pages/sop/_id/_version.vue' /* webpackChunkName: "pages/sop/_id/_version" */))
const _14222af0 = () => interopDefault(import('../pages/contractors/_companyid/_id/view.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view" */))
const _289929ea = () => interopDefault(import('../pages/contractors/_companyid/_id/view/index.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/index" */))
const _71e52953 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/file.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/file" */))
const _ee825030 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/forms.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/forms" */))
const _766a36e9 = () => interopDefault(import('../pages/contractors/_companyid/_id/view/inductions.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/view/inductions" */))
const _d117432e = () => interopDefault(import('../pages/contractors/_companyid/_id/worker-information/edit.vue' /* webpackChunkName: "pages/contractors/_companyid/_id/worker-information/edit" */))
const _5c133dbb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/address-book",
    component: _25ffe3e8,
    name: "address-book"
  }, {
    path: "/company",
    component: _5b3ab4ae,
    name: "company"
  }, {
    path: "/company-panel",
    component: _16494f40,
    name: "company-panel"
  }, {
    path: "/contractors",
    component: _3c42100a,
    name: "contractors"
  }, {
    path: "/crews",
    component: _52c71e5e,
    name: "crews"
  }, {
    path: "/employees",
    component: _58f1de71,
    name: "employees"
  }, {
    path: "/hazard-register",
    component: _13c3ae2c,
    name: "hazard-register"
  }, {
    path: "/incidents",
    component: _29cbfc66,
    name: "incidents"
  }, {
    path: "/login",
    component: _25bae0d2,
    name: "login"
  }, {
    path: "/machines",
    component: _bde94614,
    name: "machines"
  }, {
    path: "/reminders",
    component: _db61fba6,
    name: "reminders"
  }, {
    path: "/safety-alerts",
    component: _3543cfc4,
    name: "safety-alerts"
  }, {
    path: "/settings",
    component: _810bb966,
    name: "settings"
  }, {
    path: "/sites",
    component: _6ee16cb8,
    name: "sites"
  }, {
    path: "/summary",
    component: _4c01a24f,
    children: [{
      path: "",
      component: _c0ce1cdc,
      name: "summary"
    }, {
      path: "crews",
      component: _18da0bf2,
      name: "summary-crews"
    }, {
      path: "incidents",
      component: _9fffa9be,
      name: "summary-incidents"
    }]
  }, {
    path: "/support",
    component: _24c33df8,
    name: "support"
  }, {
    path: "/training-competency",
    component: _aa4f3ac8,
    name: "training-competency"
  }, {
    path: "/vehicles",
    component: _664fc0f1,
    name: "vehicles"
  }, {
    path: "/company-panel/view",
    component: _612e4607,
    children: [{
      path: "",
      component: _7e0c296c,
      name: "company-panel-view"
    }, {
      path: "edit-details",
      component: _2604efb7,
      name: "company-panel-view-edit-details"
    }, {
      path: "manage-access",
      component: _63612fc7,
      name: "company-panel-view-manage-access"
    }, {
      path: "manage-crews",
      component: _0a457d82,
      name: "company-panel-view-manage-crews"
    }, {
      path: "manage-employees",
      component: _65cb7c55,
      name: "company-panel-view-manage-employees"
    }, {
      path: "manage-access/view",
      component: _1a6c65c0,
      children: [{
        path: "",
        component: _2f459c43,
        name: "company-panel-view-manage-access-view"
      }, {
        path: "groups",
        component: _45fb789a,
        name: "company-panel-view-manage-access-view-groups"
      }]
    }]
  }, {
    path: "/company/view",
    component: _6853ab9e,
    children: [{
      path: "",
      component: _218c83a1,
      name: "company-view"
    }, {
      path: "files",
      component: _0444bac6,
      name: "company-view-files"
    }, {
      path: "objectives",
      component: _54542d1b,
      name: "company-view-objectives"
    }, {
      path: "policy",
      component: _45173473,
      name: "company-view-policy"
    }]
  }, {
    path: "/employees/new",
    component: _7166c402,
    name: "employees-new"
  }, {
    path: "/incidents/new",
    component: _689de85b,
    name: "incidents-new"
  }, {
    path: "/machines/add",
    component: _17dc6045,
    name: "machines-add"
  }, {
    path: "/safety-alerts/new",
    component: _52354228,
    name: "safety-alerts-new"
  }, {
    path: "/sites/register",
    component: _57507aa6,
    children: [{
      path: "",
      component: _4360d370,
      name: "sites-register"
    }, {
      path: ":forestid",
      component: _13a91716,
      name: "sites-register-forestid"
    }]
  }, {
    path: "/vehicles/add",
    component: _6d4f8700,
    name: "vehicles-add"
  }, {
    path: "/reminders/goto/crewgear",
    component: _4795aa12,
    name: "reminders-goto-crewgear"
  }, {
    path: "/reminders/goto/employeegear",
    component: _33872bc4,
    name: "reminders-goto-employeegear"
  }, {
    path: "/company-panel/manage-access/group/:groupuuid?",
    component: _c64f73e0,
    name: "company-panel-manage-access-group-groupuuid"
  }, {
    path: "/company-panel/manage-access/user/:useruuid?",
    component: _3b2a81a6,
    name: "company-panel-manage-access-user-useruuid"
  }, {
    path: "/company/program/:versionid",
    component: _2065fcca,
    name: "company-program-versionid"
  }, {
    path: "/company/program/:versionid?/policy",
    component: _3ee675b9,
    name: "company-program-versionid-policy"
  }, {
    path: "/employees/:id",
    component: _2de458dc,
    name: "employees-id"
  }, {
    path: "/incidents/:id",
    component: _5e536e38,
    name: "incidents-id"
  }, {
    path: "/sites/:siteid",
    component: _69818c2c,
    name: "sites-siteid"
  }, {
    path: "/summarynew/:drillid?",
    component: _31f801d5,
    name: "summarynew-drillid"
  }, {
    path: "/contractors/:companyid/agreement",
    component: _f293ce62,
    name: "contractors-companyid-agreement"
  }, {
    path: "/contractors/:companyid/reviews",
    component: _2de91c20,
    name: "contractors-companyid-reviews"
  }, {
    path: "/crews/:crewid/documents",
    component: _0b93e0e0,
    children: [{
      path: "",
      component: _4c7caa13,
      name: "crews-crewid-documents"
    }, {
      path: "files",
      component: _2f34e138,
      name: "crews-crewid-documents-files"
    }, {
      path: "gear-tracker",
      component: _72301a6e,
      name: "crews-crewid-documents-gear-tracker"
    }, {
      path: "safe-start",
      component: _a2f2b49e,
      name: "crews-crewid-documents-safe-start"
    }, {
      path: "substance-inv",
      component: _2ca6f155,
      name: "crews-crewid-documents-substance-inv"
    }]
  }, {
    path: "/crews/:crewid/monthly",
    component: _ce6bab36,
    children: [{
      path: "",
      component: _11a49128,
      name: "crews-crewid-monthly"
    }, {
      path: "audits",
      component: _5ef23f92,
      name: "crews-crewid-monthly-audits"
    }, {
      path: "emergency-drills",
      component: _31ed2b64,
      name: "crews-crewid-monthly-emergency-drills"
    }, {
      path: "safety-meetings",
      component: _1f702eee,
      name: "crews-crewid-monthly-safety-meetings"
    }, {
      path: "safety-review",
      component: _056f76ee,
      name: "crews-crewid-monthly-safety-review"
    }, {
      path: "sbos",
      component: _345fc70d,
      name: "crews-crewid-monthly-sbos"
    }, {
      path: "system-audits",
      component: _7008bdac,
      name: "crews-crewid-monthly-system-audits"
    }, {
      path: "toolbox",
      component: _be28bd68,
      name: "crews-crewid-monthly-toolbox"
    }, {
      path: "toolbox/toolbox.unit",
      component: _5c3a499a,
      name: "crews-crewid-monthly-toolbox-toolbox.unit"
    }]
  }, {
    path: "/employees/:id/edit",
    component: _7b3b3ef0,
    name: "employees-id-edit"
  }, {
    path: "/employees/:id/view",
    component: _6305460b,
    children: [{
      path: "",
      component: _cdac4f64,
      name: "employees-id-view"
    }, {
      path: "compliance",
      component: _34f39462,
      name: "employees-id-view-compliance"
    }, {
      path: "drug-alcohol",
      component: _8fc034d6,
      name: "employees-id-view-drug-alcohol"
    }, {
      path: "files",
      component: _7be20f73,
      name: "employees-id-view-files"
    }, {
      path: "forms",
      component: _3635452b,
      name: "employees-id-view-forms"
    }, {
      path: "induction",
      component: _e406a586,
      name: "employees-id-view-induction"
    }, {
      path: "linked",
      component: _9b96aa66,
      name: "employees-id-view-linked"
    }, {
      path: "medical",
      component: _520ee98d,
      name: "employees-id-view-medical"
    }, {
      path: "ppe-tracker",
      component: _37893d4c,
      name: "employees-id-view-ppe-tracker"
    }, {
      path: "record-learning",
      component: _0d4c1396,
      name: "employees-id-view-record-learning"
    }, {
      path: "training-records",
      component: _0ad9f703,
      name: "employees-id-view-training-records"
    }]
  }, {
    path: "/incidents/:id/edit",
    component: _2fb3e3d8,
    name: "incidents-id-edit"
  }, {
    path: "/machines/:machineid/edit",
    component: _ab366ccc,
    name: "machines-machineid-edit"
  }, {
    path: "/machines/:machineid/files",
    component: _72b74ad2,
    name: "machines-machineid-files"
  }, {
    path: "/machines/:machineid/induction",
    component: _d30d7fb8,
    name: "machines-machineid-induction"
  }, {
    path: "/machines/:machineid/prepurchase",
    component: _9f70f872,
    name: "machines-machineid-prepurchase"
  }, {
    path: "/sites/:siteid/view",
    component: _dd39e986,
    children: [{
      path: "",
      component: _4e577000,
      name: "sites-siteid-view"
    }, {
      path: "covid-qr",
      component: _3ae19d8b,
      name: "sites-siteid-view-covid-qr"
    }, {
      path: "emergency-information",
      component: _1338971e,
      name: "sites-siteid-view-emergency-information"
    }, {
      path: "files",
      component: _310fa725,
      name: "sites-siteid-view-files"
    }, {
      path: "hazard-id",
      component: _62e506e4,
      name: "sites-siteid-view-hazard-id"
    }, {
      path: "more-forms",
      component: _e7a9f20e,
      name: "sites-siteid-view-more-forms"
    }, {
      path: "visitors",
      component: _d3ed36f2,
      name: "sites-siteid-view-visitors"
    }]
  }, {
    path: "/sites/:siteid/visitor",
    component: _a5f190f4,
    children: [{
      path: "",
      component: _725cfcee,
      name: "sites-siteid-visitor"
    }, {
      path: "addressbook",
      component: _2f57f658,
      name: "sites-siteid-visitor-addressbook"
    }, {
      path: "contractor",
      component: _f5be6264,
      name: "sites-siteid-visitor-contractor"
    }, {
      path: "infrequent",
      component: _ec75af68,
      name: "sites-siteid-visitor-infrequent"
    }]
  }, {
    path: "/vehicles/:vehicleid/edit",
    component: _456dd860,
    name: "vehicles-vehicleid-edit"
  }, {
    path: "/contractors/:companyid/reviews/new",
    component: _b1f50924,
    name: "contractors-companyid-reviews-new"
  }, {
    path: "/crews/:crewid/audits/new",
    component: _32eed2e1,
    name: "crews-crewid-audits-new"
  }, {
    path: "/crews/:crewid/emergency-drill/new",
    component: _157a5cf0,
    name: "crews-crewid-emergency-drill-new"
  }, {
    path: "/crews/:crewid/safe-start-stop/new",
    component: _57c59194,
    name: "crews-crewid-safe-start-stop-new"
  }, {
    path: "/crews/:crewid/safety-meeting/new",
    component: _71b4c529,
    name: "crews-crewid-safety-meeting-new"
  }, {
    path: "/crews/:crewid/safety-review/health-representative-review",
    component: _6235758b,
    name: "crews-crewid-safety-review-health-representative-review"
  }, {
    path: "/crews/:crewid/safety-review/new",
    component: _76759f1c,
    name: "crews-crewid-safety-review-new"
  }, {
    path: "/crews/:crewid/safety-review/site-supervisor-review",
    component: _0a532e0b,
    name: "crews-crewid-safety-review-site-supervisor-review"
  }, {
    path: "/crews/:crewid/safety-review/worker-review",
    component: _7cd6bcfa,
    name: "crews-crewid-safety-review-worker-review"
  }, {
    path: "/crews/:crewid/safety-sheets/add",
    component: _cb6ffd8e,
    name: "crews-crewid-safety-sheets-add"
  }, {
    path: "/crews/:crewid/systems-audit/new",
    component: _1b35134a,
    name: "crews-crewid-systems-audit-new"
  }, {
    path: "/employees/:id/forms/da-consent",
    component: _455a0090,
    name: "employees-id-forms-da-consent"
  }, {
    path: "/employees/:id/forms/rol-request",
    component: _605c783b,
    name: "employees-id-forms-rol-request"
  }, {
    path: "/employees/:id/medical-information/edit",
    component: _5dee8b2f,
    name: "employees-id-medical-information-edit"
  }, {
    path: "/employees/:id/record-learning/request",
    component: _5b715e24,
    name: "employees-id-record-learning-request"
  }, {
    path: "/machines/:machineid/induction/edit",
    component: _34484ea8,
    name: "machines-machineid-induction-edit"
  }, {
    path: "/machines/:machineid/prepurchase/new",
    component: _7557a0d5,
    name: "machines-machineid-prepurchase-new"
  }, {
    path: "/sites/:siteid/emergency-information/edit",
    component: _b3624f3e,
    name: "sites-siteid-emergency-information-edit"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/new",
    component: _3bcd8626,
    name: "sites-siteid-enviromentalchecklist-new"
  }, {
    path: "/sites/:siteid/hazard-id/edit",
    component: _aac2b54a,
    name: "sites-siteid-hazard-id-edit"
  }, {
    path: "/sites/:siteid/presite/new",
    component: _75f9bf13,
    name: "sites-siteid-presite-new"
  }, {
    path: "/crews/:crewid/sbos/new/:formid?",
    component: _316b9bcb,
    name: "crews-crewid-sbos-new-formid"
  }, {
    path: "/crews/:crewid/toolbox/new/:toolboxid?",
    component: _59bb8792,
    name: "crews-crewid-toolbox-new-toolboxid"
  }, {
    path: "/crews/:crewid/sbos/view/:formid?/:id?",
    component: _42a8f99a,
    name: "crews-crewid-sbos-view-formid-id"
  }, {
    path: "/crews/:crewid/toolbox/view/:formid?/:id?",
    component: _b3ef041a,
    name: "crews-crewid-toolbox-view-formid-id"
  }, {
    path: "/contractors/:companyid/reviews/:id",
    component: _d960b2f0,
    name: "contractors-companyid-reviews-id"
  }, {
    path: "/crews/:crewid/audits/:id?",
    component: _1f38fdfb,
    name: "crews-crewid-audits-id"
  }, {
    path: "/crews/:crewid/emergency-drill/:drillid?",
    component: _107b4d3f,
    name: "crews-crewid-emergency-drill-drillid"
  }, {
    path: "/crews/:crewid/safe-start-stop/:id?",
    component: _7f313b60,
    name: "crews-crewid-safe-start-stop-id"
  }, {
    path: "/crews/:crewid/safety-meeting/:meetingid?",
    component: _5dab041e,
    name: "crews-crewid-safety-meeting-meetingid"
  }, {
    path: "/crews/:crewid/systems-audit/:id?",
    component: _42a0bd16,
    name: "crews-crewid-systems-audit-id"
  }, {
    path: "/sites/:siteid/enviromentalchecklist/:id?",
    component: _63392ff2,
    name: "sites-siteid-enviromentalchecklist-id"
  }, {
    path: "/sites/:siteid/presite/:id?",
    component: _6243ea2d,
    name: "sites-siteid-presite-id"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting",
    component: _f279453c,
    name: "crews-crewid-safety-review-id-meeting"
  }, {
    path: "/crews/:crewid/safety-review/:id?/meeting.unit",
    component: _50c787cf,
    name: "crews-crewid-safety-review-id-meeting.unit"
  }, {
    path: "/crews/:crewid/safety-review/:id?/:type?",
    component: _0bd47400,
    name: "crews-crewid-safety-review-id-type"
  }, {
    path: "/contractors/:companyid/:id",
    component: _0fa17802,
    name: "contractors-companyid-id"
  }, {
    path: "/sop/:id?/:version?",
    component: _0c14d1e0,
    name: "sop-id-version"
  }, {
    path: "/contractors/:companyid/:id?/view",
    component: _14222af0,
    children: [{
      path: "",
      component: _289929ea,
      name: "contractors-companyid-id-view"
    }, {
      path: "file",
      component: _71e52953,
      name: "contractors-companyid-id-view-file"
    }, {
      path: "forms",
      component: _ee825030,
      name: "contractors-companyid-id-view-forms"
    }, {
      path: "inductions",
      component: _766a36e9,
      name: "contractors-companyid-id-view-inductions"
    }]
  }, {
    path: "/contractors/:companyid/:id?/worker-information/edit",
    component: _d117432e,
    name: "contractors-companyid-id-worker-information-edit"
  }, {
    path: "/",
    component: _5c133dbb,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
