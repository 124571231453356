import Vue from 'vue'
import LoadingComponent from '@/components/global/loading/LoadingComponent'
import SignatureBox from '@/components/global/SignatureBox'
import InternetRequiredDialog from '@/components/global/internet-required/InternetRequiredDialog'
import InternetRequiredComp from '@/components/global/internet-required/InternetRequiredComp'
import RouteSelector from '@/components/global/RouteSelector'
import FolderView from '@/components/global/FolderView'
import HeaderView from '@/components/global/HeaderView'
import ToolCard from '@/components/global/ToolCard'
import DateTimeSelector from '@/components/global/DateTimeSelector'
import PhotoPicker from '@/components/global/photo-picker/index'
import Covid from '@/components/global/Covid'
import RestoredSaveAlert from '@/components/global/RestoredSaveAlert'
import SignatureDialog from '@/components/global/SignatureDialog'
import ButtonToggle from '@/components/global/ButtonToggle'
import ReminderInternalUpdateAlert from '@/components/global/ReminderInternalUpdateAlert'
import DownloadLinkDialog from '@/components/global/files/DownloadLinkDialog'
import UploadFileDialog from '@/components/global/files/UploadFileDialog'

import AccessCards from '@/components/company-panel/AccessCards'

// import toolkitObject from '@/tools/toolkit'

Vue.component('Loading', LoadingComponent)
Vue.component('RouteSelector', RouteSelector)
Vue.component('FolderView', FolderView)
Vue.component('HeaderView', HeaderView)
Vue.component('ToolCard', ToolCard)
Vue.component('AccessCards', AccessCards)
Vue.component('SignatureBox', SignatureBox)
Vue.component('SignatureDialog', SignatureDialog)
Vue.component('InternetRequiredDialog', InternetRequiredDialog)
Vue.component('InternetRequiredComp', InternetRequiredComp)
Vue.component('DateTimeSelector', DateTimeSelector)
Vue.component('PhotoPicker', PhotoPicker)
Vue.component('Covid', Covid)
Vue.component('RestoredSaveAlert', RestoredSaveAlert)
Vue.component('ButtonToggle', ButtonToggle)
Vue.component('ReminderInternalUpdateAlert', ReminderInternalUpdateAlert)
Vue.component('DownloadLinkDialog', DownloadLinkDialog)
Vue.component('UploadFileDialog', UploadFileDialog)

// export default ({ store }, inject) => {
//   if (process.browser) {
//     return inject('toolkit', toolkitObject)
//   }
// }
