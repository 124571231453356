export default {
  name: 'systemSignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const versionNumber = data.versionNumber
    delete data.versionNumber
    return requestFunction.methodRequest(`/companydocuments/system/${versionNumber}/signature`, 'PUT', data);
  },
  restoreSave: (ctx) => {
  }
}
