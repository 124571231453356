export default {
  name: 'policySignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const policyid = data.policyid
    delete data.policyid
    return requestFunction.methodRequest(`/companydocuments/system/policy/${policyid}/signature`, 'PUT', data);
  },
  restoreSave: (ctx) => {
  }
}
