export default {
  name: 'sbo',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/forms/view/${data.formId}`, 'PUT', data)
   },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `crews-crewid-sbos-new-formid`, params: { formid: data.formId, crewid: data.crewId }, query: { sid: ctx.saveId}})
  }
}
