<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '-NOT SET-',
    },
  },
}
</script>

<template>
  <v-card outlined>
    <v-toolbar>
      <slot name="toolbar-title">
        <v-toolbar-title v-text="title" />
      </slot>

      <v-spacer />

      <div v-if="!$vuetify.breakpoint.smAndDown">
        <slot name="toolbar-actions" />
      </div>
      <!-- puts buttons in extensions spot on toolbar if on a mobile phone -->
      <template
        v-if="$vuetify.breakpoint.smAndDown && !!$slots['toolbar-actions']"
        #extension
      >
        <v-spacer />
        <slot name="toolbar-actions" />
      </template>
    </v-toolbar>
    <slot />
  </v-card>
</template>
