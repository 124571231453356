export default {
  name: 'monthlyCrewAudit',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    data.questions = data.signageQuestions.concat(data.siteQuestions)
    return requestFunction
      .methodRequest(`/audits`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `crews-crewid-audits-new`, params: { crewid: data.crewid }, query: { sid: ctx.saveId } })
  }
}
