<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    items: {
      type: [Object, Array],
      required: false,
      default: () => ['Yes', 'No', 'N/A'],
    },
  },
  computed: {
    toggleValue: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      },
    },
    buttonItems() {
      if (Array.isArray(this.items)) {
        return this.items.map((item) => {
          return {
            text: item,
            value: item,
          }
        })
      }
      return this.items
    },
  },
}
</script>

<template>
  <v-btn-toggle
    v-model.lazy="toggleValue"
    :mandatory="mandatory"
    background-color="orange accent-4"
  >
    <v-btn
      v-for="item in buttonItems"
      :key="item.value"
      :value="item.value"
      :ripple="false"
      color="orange accent-4"
      dark
      large
      v-text="item.text"
    />
  </v-btn-toggle>
</template>

<style lang="scss" scoped>
.v-btn-toggle > .v-btn.v-btn--active {
  background-color: #000 !important;
  color: #fff;
}
</style>
