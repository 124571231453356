function initState() {
  return {
    updateAvailable: false,
  }
}

export const state = initState

export const mutations = {
  appUpdateAvailable(state) {
    state.updateAvailable = true
  },
  resetState(state) {
    const s = initState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },
}
