export default {
  name: 'hazardIdSignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(
        `/hazards/view/${data.hazardidId}/signoff/${data.employeeId}`,
        'PUT',
        { signature: data.signature, signedon: data.signedon }
      )
  },
  restoreSave: (ctx) => {
  }
}
