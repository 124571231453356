export default {
    name: 'newAction',
    // If Request is a mini Save
    miniSave: true,
    // Uploads save to server
    uploadFunction: ({ requestFunction, data }) => {
        return requestFunction
            .methodRequest('/actions', 'PUT', {
                action: data.action,
                crewid: data.crewid,
                assignedto: data.assignedto
            })
    }
}