export const state = () => ({
  messages: [],
})

export const mutations = {
  addMessage(state, n) {
    state.messages.push(n)
  },
  removeMessage(state) {
    state.messages.shift()
  },
}
