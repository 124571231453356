<script>
export default {
  name: 'DownloadLinkDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="800"
  >
    <v-card>
      <v-toolbar>
        <v-toolbar-title> Open Download Link </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="py-5">
        <p>
          Make sure to download any files before going out of internet coverage.
          Files do not store offline automatically.
        </p>
        <h5>
          Do not share this link with anyone. Do not share this file unless you
          have explicit permission from the file owner.
        </h5>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="showDialog = false"> Close </v-btn>
        <v-spacer />
        <v-btn :href="link" color="primary" target="_blank">
          View/Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
