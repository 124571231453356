import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/remote-config'

const config = {
  apiKey: 'AIzaSyA2OQDERAAIWRqn1qz05ouejl7Fnf9NC0w',
  authDomain: 'northern-lights-5b169.firebaseapp.com',
  databaseURL: 'https://northern-lights-5b169.firebaseio.com',
  projectId: 'northern-lights-5b169',
  storageBucket: 'northern-lights-5b169.appspot.com',
  messagingSenderId: '25605371983',
  appId: '1:25605371983:web:fe3d487a3115f171',
}
firebase.initializeApp(config)

export default firebase
