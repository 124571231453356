export default {
  name: 'employeeGear',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
      return requestFunction
      .methodRequest(
          `/crewconfig/gear/${data.id}`,
        data.gearid !== null ? 'POST' : 'PUT',
        data
      )
    },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
  ctx.router.push({ name: `crews-crewid-ppe-tracker`, params: { id: data.crewid }, query: { sid: ctx.saveId } })
  }
}
