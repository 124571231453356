import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export default ({ app, store }) => {
  if (process.env.ANALYTICS_SITE_ID) {
    Vue.use(VueMatomo, {
      router: app.router,
      host: 'https://analytics.oshtrak.com',
      siteId: process.env.ANALYTICS_SITE_ID,
      enableHeartBeatTimer: true,
      debug: true,
      //   userId: window.$nuxt.$store.state.userId,
      cookieDomain: '*.oshtrak.com',
      domains: '*.oshtrak.com',
      preInitActions: [
        ['setDomains', ['*.oshtrak.com']],
        ['enableCrossDomainLinking'],
      ],
    })
  }
}
