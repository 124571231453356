export default {
  name: 'employeeInfo',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const id = data.employeeid;
    delete data.employeeid;
    return requestFunction
      .methodRequest(`/employee/info/${id}`, 'POST', data)
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `employees-id-edit`, params: { id: data.employeeid }, query: { sid: ctx.saveId } })
  }
}
