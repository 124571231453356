export default {
  name: 'sboSignature',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(
        `/forms/view/${data.formId}/history/${data.formHistoryId}/signature`,
        'POST',
        { signature: data.signature }
      )
   },
  restoreSave: (ctx) => {}
}
