<script>
export default {
  props: {
    saveId: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    deleteSave() {
      if (window.confirm('Are you sure you want to delete this save?')) {
        this.$save.removeSave(this.saveId).then(() => this.$router.go())
      }
    },
  },
}
</script>

<template>
  <v-alert icon="mdi-content-save-alert-outline" type="warning" dense prominent>
    <v-row dense align="center">
      <v-col class="grow"> Showing saved draft </v-col>
      <v-col class="shrink">
        <v-btn v-if="saveId !== null" text @click="deleteSave">
          Delete Save
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
