export default {
  date: 'DD/MM/YYYY',
  datefn: 'dd/MM/yyyy',
  datetime: 'DD/MM/YYYY HH:mm',
  datetimesec: 'DD/MM/YYYY HH:mm:ss',
  datetimefn: 'dd/MM/yyyy HH:mm',
  datetimesecfn: 'dd/MM/yyyy HH:mm:ss',
  dbdate: "YYYY-MM-DD'T'HH:mm:ss",
  dbdatefn: "yyyy-MM-dd'T'HH:mm:ss",
  isoDate: 'YYYY-MM-DD',
  isoDatefn: 'yyyy-MM-dd',
  isoDatetime: 'YYYY-MM-DD HH:mm',
  isoDateSec: 'YYYY-MM-DD HH:mm:ss',
  isoDateSecFn: 'yyyy-MM-dd HH:mm:ss',
  isoDatetimefn: 'yyyy-MM-dd HH:mm',
  sortdate: 'yyyyMMDD',
  readingDate: 'dddd Do MMMM yyyy',
  longdate: 'PPPP',
  longdatetime: 'PPPP HH:mm a',
  yearmonthfn: 'yyyy-MM',
}
