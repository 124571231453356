<script>
import SignaturePad from 'signature_pad'
export default {
  name: 'SignatureBox',

  data() {
    return {
      signaturePadObject: null,
      id: `signature-box-${Math.random().toString(36).substr(2, 5)}`,
    }
  },
  mounted() {
    this.mountSignaturePad()
  },
  methods: {
    mountSignaturePad() {
      const e = document.querySelector(`#${this.id}`)
      if (e) {
        this.signaturePadObject = new SignaturePad(e)
      }
    },
    clearSignaturePad() {
      this.signaturePadObject.clear()
    },
    getSignature() {
      if (this.isSignatureEmpty()) {
        return ''
      }
      return this.signaturePadObject.toDataURL('image/png')
    },
    isSignatureEmpty() {
      return this.signaturePadObject.isEmpty()
    },
  },
}
</script>

<template>
  <div>
    <div class="signature-box">
      <canvas :id="id" />
    </div>
    <v-btn class="white--text" small color="red" @click="clearSignaturePad">
      Clear
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.signature-box {
  canvas {
    background-color: #dcdcdc;
    border-bottom: 4px dashed black;
  }
}
</style>
