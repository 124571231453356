export default {
  name: 'hazardInfo',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    const siteId = data.siteid;
    delete data.siteId;
    return requestFunction
      .methodRequest(`/hazards/view/${siteId}/info`, 'PUT', data)
  },
  restoreSave: (ctx) => {
  }
}
