import request from '@/tools/request'

export const state = () => ({
  employeeArr: [],
})

export const mutations = {
  addEmployeesToArray(state, employeeArr) {
    state.employeeArr = employeeArr
  },
}

export const getters = {
  employeeDetails: (state) => (employeeid) => {
    const findContractorCompany = state.employeeArr.find(
      (x) => String(x.id) === String(employeeid)
    )
    return findContractorCompany || {}
  },
}

export const actions = {
  getEmployees({ commit }) {
    return new Promise((resolve, reject) => {
      return request
        .getRequest('/helper/employees')
        .then((data) => {
          commit('addEmployeesToArray', data.data)
        })
        .then(() => resolve())
        .catch((err) => reject(err))
    })
  },
}
