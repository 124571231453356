
export default {
  name: 'machineInduction',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/register/machine/induction/${data.machineid}`, 'POST', data.questions)


  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `machines-machineid-induction-edit`, params: { machineid: data.machineid }, query: { sid: ctx.saveId } })
  }
}
