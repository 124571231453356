export default {
    name: 'dismissSbo',
    miniSave: true,
    uploadFunction: ({ requestFunction, data, saveId }) => {
        return requestFunction
            .methodRequest(`/crewconfig/sbos/${data.crewid}/signoff`, 'PUT', {
                signature: data.signature,
                sboid: data.sboid,
                date: data.date
            })
    },
    restoreSave: (ctx) => {       
    }
}
