export default {
  name: 'contractorReview',
  miniSave: false,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/contractors/${data.companyid}/reviews/${data.contractorid}`, 'PUT', data);
  },
  restoreSave: (ctx) => {
    const data = ctx.saveObj.data;
    ctx.router.push({ name: `contractors-companyid-reviews-new`, params: { companyid: data.companyid }, query: { sid: ctx.saveId } });
  }
}
