<script>
import InternetRequired from './InternetRequired'
export default {
  components: {
    InternetRequired,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      onlineStatus: true,
    }
  },
  mounted() {
    this.checkConnection()
    window.addEventListener('online', this.checkConnection)
    window.addEventListener('offline', this.checkConnection)
  },
  methods: {
    checkConnection() {
      return this.$save.onlineStatus().then((onlineStatus) => {
        this.onlineStatus = onlineStatus
      })
    },
  },
}
</script>

<template>
  <div>
    <InternetRequired v-if="!onlineStatus" :to="to" :back-button="false" />
    <slot v-else />
  </div>
</template>
