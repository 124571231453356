<script>
export default {
  name: 'Loading',
  props: {
    fullPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<template>
  <div :class="fullPage ? 'loading-parent fullpage' : 'loading-parent comp'">
    <div class="loading">
      <img
        src="@/assets/images/logo/mobile-logo.png"
        class="animated pulse infinite slower"
      /><br />
      <h3>Loading</h3>
      <v-progress-linear color="primary" buffer-value="0" stream />
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;

  .loading {
    text-align: center;

    h3 {
      margin: 5px 0 10px;
      font-family: 'Roboto', sans-serif;
      color: var(--v-primary-base);
    }

    img {
      width: 140px;
    }
  }

  &.fullpage {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 1019;
    color: var(--v-primary-base);
  }

  &.comp {
    margin-top: 15px;
  }
}
</style>
