import {
  format,
  formatDistanceToNow,
  parse,
  parseISO,
  isValid,
  isAfter,
  addHours,
  addDays,
  add,
  sub,
  isEqual,
  compareAsc,
  compareDesc,
  differenceInMonths,
  differenceInCalendarDays,
  isToday,
  isBefore,
} from 'date-fns'
import dateFormats from '~/constants/dateFormat'

function countMonths(date, dateFormat, compare, compareFormat) {
  return differenceInMonths(
    parse(date, dateFormat, new Date()),
    parse(compare, compareFormat, new Date())
  )
}

function countDays(date, dateFormat, compare, compareFormat) {
  return differenceInCalendarDays(
    parse(date, dateFormat, new Date()),
    parse(compare, compareFormat, new Date())
  )
}

function validDate(date, dateFormat = dateFormats.datefn) {
  return isValid(parse(date, dateFormat, new Date()))
}

function todayFormat(dateFormat) {
  return format(new Date(), dateFormat)
}

function formatDate(date, currentFormat, newFormat) {
  return format(parse(date, currentFormat, new Date()), newFormat)
}

function addDaysFormat(date, currentFormat, days, format) {
  let d
  if (!date) {
    d = new Date()
  } else {
    d = parse(date, currentFormat, new Date())
  }
  return formatDate(addDays(d, days), format)
}

function isDateEqual(date, format, secondDate, secondFormat) {
  return isEqual(
    parse(date, format, new Date()),
    parse(secondDate, secondFormat, new Date())
  )
}

// Format Date for MYSQL Format. If no date is passed, today is used.
function formatDateForDB({ date = false, dateFormat, time = false } = {}) {
  let returnFormat = dateFormats.isoDatefn
  if (time) {
    returnFormat = dateFormats.isoDateSecFn
  }
  if (date !== false) {
    if (validDate(date, dateFormat)) {
      const d = parse(date, dateFormat, new Date())
      return format(d, returnFormat)
    }
    return null
  }
  return format(new Date(), returnFormat)
}

// Format Date for People Reading. If no date is passed, today is used.
function formatDateForReading({ date = false, dateFormat, time = false } = {}) {
  let returnFormat = 'PPPP'
  if (time) {
    returnFormat = 'PPPPpp'
  }
  if (date !== false) {
    if (validDate(date, dateFormat)) {
      const d = parse(date, dateFormat, new Date())
      return format(d, returnFormat)
    }
    return 'No Date'
  }
  return format(new Date(), returnFormat)
}

// @param date - the date that should be after the other one to return true
function dateIsAfter(date, dateToCompare, dateFormat = dateFormats.datefn) {
  return isAfter(
    parse(date, dateFormat, new Date()),
    parse(dateToCompare, dateFormat, new Date())
  )
}

function dateIsBefore(date, dateToCompare, dateFormat = dateFormats.datefn) {
  return isBefore(
    parse(date, dateFormat, new Date()),
    parse(dateToCompare, dateFormat, new Date())
  )
}

function isSameOrBefore(date, format, compare, compareFormat) {
  return (
    compareAsc(
      parse(date, format, new Date()),
      parse(compare, compareFormat, new Date())
    ) >= 0
  )
}

function compareDatesAsc(date, format, compare, compareFormat) {
  return compareAsc(
    parse(date, format, new Date()),
    parse(compare, compareFormat, new Date())
  )
}

function compareDatesDesc(date, format, compare, compareFormat) {
  return compareDesc(
    parse(date, format, new Date()),
    parse(compare, compareFormat, new Date())
  )
}

function addHoursToDate(date, dateFormat = dateFormats.datefn, hours) {
  const timeToAdd = parse(date, dateFormat, new Date())
  const newTime = addHours(timeToAdd, hours)
  return format(newTime, dateFormat)
}

function addToDate(date, dateFormat = dateFormats.datefn, config, newFormat) {
  const d = parse(date, dateFormat, new Date())
  const newDate = add(d, config)
  return format(newDate, newFormat ?? dateFormat)
}

function parseDate(date, dateFormat = dateFormats.datefn) {
  return parse(date, dateFormat, new Date())
}

function addToToday(config, dateFormat = dateFormats.datefn) {
  const d = new Date()
  const newDate = add(d, config)
  return format(newDate, dateFormat)
}

function isDateToday(date, format) {
  return isToday(parse(date, format, new Date()))
}

export default {
  formatDateForDB,
  formatDateForReading,
  validDate,
  todayFormat,
  dateIsAfter,
  formatDate,
  parseDate,
  addHours,
  addHoursToDate,
  addDays,
  isAfter,
  format,
  parse,
  parseISO,
  isValid,
  addDaysFormat,
  formatDistanceToNow,
  add,
  sub,
  isEqual,
  isDateEqual,
  addToDate,
  isSameOrBefore,
  addToToday,
  countMonths,
  countDays,
  compareDatesAsc,
  compareDatesDesc,
  isDateToday,
  dateIsBefore,
}
