export default {
    name: 'employeeGear',
    miniSave: true,
    uploadFunction: ({ requestFunction, data, saveId }) => {
        return requestFunction
        .methodRequest(
            `/employee/gear/${data.id}`,
          data.gearid !== null ? 'POST' : 'PUT',
          data
        )
      },
    restoreSave: (ctx) => {
      const data = ctx.saveObj.data;
    ctx.router.push({ name: `employees-id-ppe-tracker`, params: { id: data.employeeid }, query: { sid: ctx.saveId } })
    }
  }
