export default {
  name: 'employeeDrugAlcoholRecord',
  miniSave: true,
  uploadFunction: ({ requestFunction, data, saveId }) => {
    return requestFunction
      .methodRequest(`/employee/drugrecords/${data.employeeid}`, 'PUT', data)
  },
  restoreSave: (ctx) => {
    // const data = ctx.saveObj.data;
    // ctx.router.push({ name: `employees-id-medical-information-edit`, params: { id: data.employeeid }, query: { sid: ctx.saveId } })
  }
}
