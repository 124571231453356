export default {
    name: 'substanceInventory',
    miniSave: true,
    uploadFunction: ({ requestFunction, data, saveId }) => {
        switch(data.requestType) {
            case 'add':
                return requestFunction
                    .methodRequest(`/crewconfig/substances/${data.crewid}`, 'PUT', data)
            case 'edit':

                return requestFunction
                    .methodRequest(`/crewconfig/substances/${data.crewid}`, 'POST', data)
            case 'archive':

                return requestFunction
                    .methodRequest(`/crewconfig/substances/${data.crewid}/archive`, 'POST', data)
        }
    },
    restoreSave: (ctx) => {
    }
}